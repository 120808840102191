import {Component, Input, OnInit} from '@angular/core';
import {SECTION_LIST_DISPLAY} from "../../common/nav-menu-list";
import {LANGUAGE_PROFICIENCY} from "../../../resume/common/resume-section-model/languagae.model";
import {WEBSITE_SECTION_TYPE} from "../../common/website-model/website-section.model";
import {LanguageAttributes} from "../../common/website-model/language.model";
import {WebsiteSectionSubCardComponent} from "../../website-section-sub-card/website-section-sub-card.component";
import {DateFormatter, DateUtil} from "../../../../common/util/date.util";
import {DatePipe} from "@angular/common";
import {WebsiteSectionCardComponent} from "../../website-section-card/website-section-card.component";

@Component({
  selector: 'app-w-language',
  standalone: true,
  imports: [
    WebsiteSectionSubCardComponent,
    DatePipe,
    WebsiteSectionCardComponent
  ],
  templateUrl: './w-language.component.html',
  styleUrl: './w-language.component.scss'
})
export class WLanguageComponent implements OnInit {
  @Input() languageAttributes: LanguageAttributes | any;
  SECTION_LIST_DISPLAY = SECTION_LIST_DISPLAY;
  WEBSITE_SECTION_TYPE = WEBSITE_SECTION_TYPE;
  protected readonly DateUtil = DateUtil;
  protected readonly DateFormatter = DateFormatter;

  constructor() {
  }

  ngOnInit(): void {
    this.languageAttributes ? this.prepareLanguages() : null;
  }

  private prepareLanguages(): void {
    this.languageAttributes.languages.forEach((language: any) => {
      switch (language.languageProficiency) {
        case LANGUAGE_PROFICIENCY.GOOD_KNOWLEDGE:
          language['proficiency'] = 'Good Knowledge';
          language['barWidth'] = 'w-1/4';
          break;
        case LANGUAGE_PROFICIENCY.HIGHLY_PROFICIENT:
          language['proficiency'] = 'Highly Proficient';
          language['barWidth'] = 'w-2/4';
          break;
        case LANGUAGE_PROFICIENCY.VERY_GOOD_COMMAND:
          language['proficiency'] = 'Very Good Command';
          language['barWidth'] = 'w-3/4';
          break;
        case LANGUAGE_PROFICIENCY.NATIVE_SPEAKER:
          language['proficiency'] = 'Native Speaker';
          language['barWidth'] = 'w-full';
          break;
      }
    });
  }
}
