import {Component, OnInit} from '@angular/core';
import {CONSTANTS} from "../../common/constant/constant";
import {NgOptimizedImage} from "@angular/common";
import {PoweredByFooterComponent} from "../powered-by-footer/powered-by-footer.component";
import {LinkUtil} from "../../common/util/link.util";

@Component({
  selector: 'tal-page-not-found',
  standalone: true,
  imports: [
    NgOptimizedImage,
    PoweredByFooterComponent
  ],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss'
})
export class PageNotFoundComponent implements OnInit {

  ngOnInit(): void {
    this.navigateInThreeSecond();
  }

  navigateToTalenlio(): void {
      LinkUtil.openLinkInNewTab(CONSTANTS.TALENLIO_LINK);
  }

  private navigateInThreeSecond(): void {
    setTimeout(() => {
      this.navigateToTalenlio();
    }, 3000);
  }
}
