<div class="container-fluid">
  <div class="portfolio-layout">
    <div class="sidebar-section">
      <app-w-sidebar [personalInformationAttributes]="personalInformationAttributes"
                     [headerSectionAttribute]="headerSectionAttribute"
                     [socialLinkAttributes]="socialLinkAttributes"></app-w-sidebar>
    </div>
    <div class="default-website-section">
      @if (summaryAttributes) {
        <app-w-about [summaryAttributes]="summaryAttributes"></app-w-about>
      }
      @if (educationAttributes) {
        <app-w-education [educationAttributes]="educationAttributes"></app-w-education>
      }
      @if (workExperienceAttributes) {
        <app-w-work [workExperienceAttributes]="workExperienceAttributes"></app-w-work>
      }
      @if (certificateAttributes) {
        <app-w-certificate [certificateAttributes]="certificateAttributes"></app-w-certificate>
      }
      @if (projectAttributes) {
        <app-w-project [projectAttributes]="projectAttributes"></app-w-project>
      }
      @if (achievementAttributes) {
        <app-w-achievement [achievementAttributes]="achievementAttributes"></app-w-achievement>
      }
      @if (languageAttributes) {
        <app-w-language [languageAttributes]="languageAttributes"></app-w-language>
      }
      @if (skillAttributes) {
        <app-w-skill [skillAttributes]="skillAttributes"></app-w-skill>
      }
      @if (personalInformationAttributes) {
        <app-w-footer></app-w-footer>
      }
    </div>
  </div>
</div>
