<div id="{{WEBSITE_SECTION_TYPE.EDUCATION}}" class="education-section-container mt-20">
  <tal-website-section-card logo="assets/website/education-icon.png"
                            [title]="SECTION_LIST_DISPLAY.EDUCATION">
  </tal-website-section-card>
  @for (qualification of educationAttributes?.qualifications; let index = $index; track educationAttributes?.id) {
    <div class="card-secondary-container">
      <app-website-section-sub-card [title]="qualification?.degree"
                                    [subTitle]="qualification?.region"
                                    [present]="qualification?.ongoing"
                                    [startDate]="qualification?.startDate"
                                    [endDate]="qualification?.endDate"
                                    [dateDifference]="getDateDifference(qualification?.startDate , qualification?.endDate)"
                                    [location]="qualification?.university">
      </app-website-section-sub-card>
    </div>
  }
</div>
