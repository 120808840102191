<ng-container>
  <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
    <div class="flex items-center justify-start">
      @if (!isClassicType) {
        <i class="ti ti-language text-sm sm:text-2xl"
           [ngStyle]="{'color': resumeTheme.iconColor}">
        </i>
      }
      <div class="resume-section-title"
           [ngClass]="isClassicType ? 'pl-0' : 'pl-1' "
           [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.primaryColor : resumeTheme.twoColumnHighlightedHeadingText">
        {{ sectionTitle || RESUME_SECTION_TYPE_DISPLAY.LANGUAGE | titlecase }}
      </div>
    </div>
  </div>
  <hr [style.border-color]="isClassicType ? classicLayoutTheme?.borderColor : resumeTheme.iconColor "
      class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
</ng-container>
<div class="w-full grid"
     [ngClass]="layoutType==LAYOUT_TYPE.ONE_COLUMN ? 'grid-cols-2 gap-2 sm:grid-cols-3 mb-3' : 'grid-cols-1'">
  @for (resumeHasLanguageOutDto of resumeHasLanguageOutDtos; track resumeHasLanguageOutDto?.id) {
    <div [ngClass]="{'resume-section-content-between':layoutType === LAYOUT_TYPE.TWO_COLUMN }">
      <div class="h-full flex flex-col items-start justify-center"
           [ngClass]="{'section-card':layoutType==LAYOUT_TYPE.ONE_COLUMN}">
        <div class="resume-section-container-title"
             [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.primaryColor : resumeTheme.twoColumnHighlightedHeadingText">
          {{ resumeHasLanguageOutDto.language | titlecase }}
        </div>
        @if (resumeHasLanguageOutDto?.languageProficiency) {
          <div class="resume-section-container-text"
               [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.secondaryColor : resumeTheme.twoColumnHighlightedSecondaryText">
            {{ LANGUAGE_PROFICIENCY_DISPLAY[resumeHasLanguageOutDto.languageProficiency] }}
          </div>
        }
      </div>
    </div>
  }
</div>
