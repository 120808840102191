<ng-container>
  <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
    <div class="flex items-center justify-start">
      @if (!isClassicType) {
        <div class="text-sm sm:text-2xl"
             [ngClass]="ResumeIconModel.getIconForResume().achievement"
             [ngStyle]="{'color': resumeTheme.iconColor}">
        </div>
      }
      <div class="font-semibold resume-section-title flex flex-row items-center"
           [ngClass]="isClassicType ? 'pl-0' : 'pl-1'"
           [ngStyle]="{'color': resumeTheme.primaryColor}">
        {{ sectionTitle || RESUME_SECTION_TYPE_DISPLAY.ACHIEVEMENT | titlecase }}
      </div>
    </div>
  </div>
  <hr [style.border-color]="isClassicType ? classicLayoutTheme?.borderColor : resumeTheme.iconColor"
      class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
</ng-container>
<div class="section-description">
  @for (resumeHasAchievementOutDto of resumeHasAchievementOutDtos; let index = $index; track resumeHasAchievementOutDto?.rowIndex) {
    <div class="w-full flex flex-col items-center justify-center resume-section-content-between section-card">
      <div class="w-full">
        <div class="flex items-center justify-between">
          <div class="resume-section-container-title"
               [ngStyle]="{'color': resumeTheme?.primaryColor}">
            {{ resumeHasAchievementOutDto?.title }}
          </div>
        </div>
        <div class="resume-section-container-text flex items-center justify-start"
             [ngStyle]="{'color': resumeTheme?.secondaryColor}">
          <div>{{ resumeHasAchievementOutDto?.region | titlecase }}</div>
        </div>
        @if (resumeHasAchievementOutDto?.description) {
          <div class="resume-section-container-description"
               [ngStyle]="{'color': resumeTheme?.primaryColor}"
               [innerHTML]="resumeHasAchievementOutDto?.description"></div>
        }
        @if (resumeHasAchievementOutDto?.link) {
          <div class="flex items-center justify-start">
            @if (!isClassicType) {
              <div class="text-xs sm:text-lg rotate-45"
                   [ngClass]="ResumeIconModel.getIconForResume().link"
                   [ngStyle]="{'color': resumeTheme?.iconColor}">
              </div>
            }
            <div class="cursor-pointer resume-section-container-text"
                 [ngClass]="isClassicType ? 'pl-0 sm:pl-0' : 'pl-1 sm:pl-2'"
                 [ngStyle]="{'color': resumeTheme?.iconColor}"
                 (click)="openLinkInNewTab(resumeHasAchievementOutDto?.link)">
              @if (!isClassicType) {
                Achievement link
              } @else {
                <div class="color-primary">
                  Link
                </div>
              }
            </div>

          </div>
        }
      </div>
    </div>
  }
</div>
