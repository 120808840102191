<div id="{{WEBSITE_SECTION_TYPE.LANGUAGE}}" class="languages-section-container mt-20">
  <tal-website-section-card logo="assets/website/language-icon.png"
                            [title]="SECTION_LIST_DISPLAY.LANGUAGE">
  </tal-website-section-card>
  @for (language of languageAttributes?.languages; track language?.id) {
    <app-website-section-sub-card [title]="language?.name"
                                  [subTitle]="language?.proficiency">
    </app-website-section-sub-card>
  }
</div>
