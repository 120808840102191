import {ClassicLayoutModel} from "./resume-theme.model";

export class ResumeClassicLayoutUtil {
    public static getClassicLayoutTheme(): ClassicLayoutModel {
        return {
            backgroundColor: 'var(--color-white-extra-dark)',
            sidebarColor: 'var(--color-white-extra-dark)',
            headerColor: 'var(--color-white-extra-dark)',
            primaryColor: 'var(--color-black-light-dark)',
            secondaryColor: 'var(--color-light-gray)',
            headerText: 'var(--color-black-light-dark)',
            sidebarText: 'var(--color-black-light-dark)',
            borderColor: 'var(--color-light-white-gray)'
        };
    }
}
