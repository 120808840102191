<div id="{{WEBSITE_SECTION_TYPE.WORK_EXPERIENCE}}" class="work-experience-container mt-20">
  <tal-website-section-card logo="assets/website/work-experience-icon.png"
                            [title]="SECTION_LIST_DISPLAY.WORK_EXPERIENCE"
                            [startDate]="startDate | date: DateFormatter.MONTH_YEAR"
                            [duration]="DateUtil?.getTotalYearsAndMonthCalculation(workExperienceAttributes?.experiences)">

  </tal-website-section-card>
  @for (experience of workExperienceAttributes?.experiences; let index = $index; track experience?.id) {
    <div class="card-secondary-container">
      <app-website-section-sub-card [title]="experience?.jobTitle"
                                    [subTitle]="experience?.companyName"
                                    [present]="experience?.workingHere"
                                    [startDate]="experience?.startDate"
                                    [endDate]="experience?.endDate"
                                    [dateDifference]="getDateDifference(experience?.startDate , experience?.endDate)"
                                    [location]="experience?.location"
                                    [description]="experience?.description">
      </app-website-section-sub-card>
    </div>
  }
</div>
