export enum RESUME_ICON_LIST {
  ACHIEVEMENT = "ti ti-gift",
  CERTIFICATE = "ti ti-book",
  EDUCATION = "ti ti-school",
  EMAIL = "ti ti-mail",
  EXTERNAL_LINK = "ti ti-external-link",
  HOBBIES = "ti ti-headphones",
  LANGUAGE = "ti ti-language",
  LINK = "ti ti-link",
  location = "ti ti-map-pin",
  phone = "ti ti-phone",
  PROJECT = "ti ti-clipboard",
  REFERENCE = "ti ti-users",
  SKILL = "ti ti-award",
  SUMMARY = "ti ti-notes",
  WORK_EXPERIENCE = "ti ti-briefcase",
  LINKEDIN = "ti ti-brand-linkedin",
  WEBSITE = "ti ti-world",
  BEHANCE = "ti ti-brand-behance",
  DRIBBBLE = "ti ti-brand-dribbble",
  GITHUB = "ti ti-brand-github"
}
