<div id="{{WEBSITE_SECTION_TYPE.CERTIFICATE}}" class="certificate-section-container mt-20">
  <tal-website-section-card logo="assets/website/certificate-icon.png"
                            [title]="SECTION_LIST_DISPLAY.CERTIFICATE">
  </tal-website-section-card>
  @for (certificate of certificateAttributes?.certifications; let index = $index; track certificate?.id) {
    <div class="card-secondary-container">
      <app-website-section-sub-card [title]="certificate?.name"
                                    [subTitle]="certificate?.certifiedBy"
                                    [url]="certificate?.link"
                                    [startDate]="certificate?.certificationDate"
                                    [endDate]="certificate?.expirationDate"
                                    [dateDifference]="getDateDifference(certificate?.certificationDate , certificate?.expirationDate)">
      </app-website-section-sub-card>
    </div>
  }
</div>
