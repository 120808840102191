<ng-container>
    <div class="flex items-center justify-start resume-section-title-bottom">
        <div class="flex items-center justify-start">
            @if (!isClassicType) {
                <div class="text-sm sm:text-2xl"
                     [ngClass]="ResumeIconModel.getIconForResume().project"
                     [ngStyle]="{'color': resumeTheme.iconColor}">
                </div>
            }
            <div class="flex flex-row items-center resume-section-title"
                 [ngStyle]="{'color': resumeTheme.primaryColor}"
                 [ngClass]="isClassicType ? 'pl-0' : 'pl-1' ">
                {{ sectionTitle || RESUME_SECTION_TYPE_DISPLAY.PROJECTS | titlecase }}
                @if (resumeHasProjectOutDtos && !isClassicType) {
                    <span class="chip px-2 ml-1 text-xxs sm:text-xs"
                          [ngStyle]="{'background': resumeTheme?.skillChipBackground, 'color': resumeTheme?.skillChipTextColor}">
                    {{ resumeHasProjectOutDtos?.length }}
                </span>
                }
            </div>
        </div>
    </div>
    <hr [style.border-color]="isClassicType ? classicLayoutTheme?.borderColor : resumeTheme.iconColor "
        class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
</ng-container>
<div class="section-description">
    <div class="w-full">
        @for (resumeHasProjectOutDto of resumeHasProjectOutDtos; let index = $index; track resumeHasProjectOutDto?.id) {
            <div class="w-full flex flex-col items-center justify-center resume-section-content-between section-card">
                <div class="w-full">
                    <div class="flex items-start justify-between">
                        <div class="break-all flex flex-col items-start justify-between"
                             [ngClass]="resumeHasProjectOutDto?.teamSize || resumeHasProjectOutDto?.startDate ? 'w-3/5' : 'w-full'">
                            <div class="flex flex-row items-center justify-start">
                                <div class="resume-section-container-title break-normal"
                                     [ngStyle]="{'color': resumeTheme.primaryColor}">
                                    {{ resumeHasProjectOutDto?.projectTitle }}
                                </div>
                                @if (resumeHasProjectOutDto?.projectLink && !isClassicType) {
                                    <div [ngClass]="ResumeIconModel.getIconForResume().externalLink"
                                         class="text-sm sm:text-xl leading-none pl-2 cursor-pointer"
                                         [ngStyle]="{'color': resumeTheme.iconColor}"
                                         (click)="openUrlInNewTab(resumeHasProjectOutDto?.projectLink)">
                                    </div>
                                }
                            </div>
                            <div [ngStyle]="{'color': resumeTheme?.secondaryColor}"
                                 class="resume-section-container-text break-normal">
                                {{ resumeHasProjectOutDto?.yourRole }}
                                @if (resumeHasProjectOutDto?.yourRole && resumeHasProjectOutDto?.workedFor) {
                                    &#64;
                                }
                                @if (resumeHasProjectOutDto?.workedFor) {
                                    <span>{{ resumeHasProjectOutDto?.workedFor }}</span>
                                }
                            </div>
                        </div>
                        <div class="resume-section-container-text flex flex-col items-center justify-between">
                            @if (resumeHasProjectOutDto?.startDate) {
                                <div class="resume-section-container-text break-normal"
                                     [ngStyle]="{'color': resumeTheme.secondaryColor}">
                                    {{ resumeHasProjectOutDto?.startDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                    -
                                    @if (resumeHasProjectOutDto?.workingHere || !resumeHasProjectOutDto?.endDate) {
                                        <span>Present</span>
                                    } @else {
                                        {{ resumeHasProjectOutDto?.endDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                    }
                                </div>
                            }
                            @if (resumeHasProjectOutDto?.teamSize) {
                                <div class="chip px-2 text-xxs sm:text-xs"
                                     [ngStyle]="{'background': resumeTheme?.skillChipBackground, 'color': isClassicType? resumeTheme?.secondaryColor: resumeTheme?.skillChipTextColor}">
                                    Team size
                                    - {{ resumeHasProjectOutDto?.teamSize }}
                                </div>
                            }
                        </div>
                    </div>
                    @if (resumeHasProjectOutDto?.description) {
                        <div class="resume-section-container-description"
                             [ngStyle]="{'color': resumeTheme?.primaryColor}"
                             [innerHTML]="resumeHasProjectOutDto?.description"></div>
                    }
                    <div class="flex flex-wrap">
                        @for (tool of resumeHasProjectOutDto?.toolsAndTechnologies; track tool?.id) {
                            <div class="chip p-1 m-1 text-blue-1000 text-xxs sm:text-xs break-normal"
                                 [ngClass]="{'border-solid border':isClassicType}"
                                 [style.border-color]="classicLayoutTheme?.borderColor"
                                 [ngStyle]="{'background': resumeTheme?.skillChipBackground,
                                        'color': resumeTheme?.skillChipTextColor}">
                                {{ tool.name }}
                            </div>
                        }
                    </div>
                    @if (resumeHasProjectOutDto?.projectLink && isClassicType) {
                        <a [href]="resumeHasProjectOutDto?.projectLink"
                           class="color-primary resume-section-container-text">Link</a>
                    }
                </div>
            </div>
        }
    </div>
</div>
