<div class="h-screen w-full flex flex-col items-center justify-center font-poppins p-4 sm:p-0 bg-cover bg-center"
     style="background-image: url('../../../../assets/resume-background.jpeg')">
    <div class="white-card-container box-shadow flex flex-col
              justify-center items-center w-full sm:w-6/12 m-auto px-5 sm:px-16 py-7 sm:py-10">
        <img src="assets/not-found.svg"
             class="w-full"
             style="max-width: 465px;"
             alt="not-found">
        <div class="text-lg font-bold mb-2 text-heading text-center">
            Oops! This job is no longer accepting applications
        </div>
        <div class="text-sm mb-3 text-subheading text-center">
            We encourage you to explore other opportunities on the careers page
        </div>
        <lib-button (buttonClick)="navigateToCareerPage()">
            Go to career page
        </lib-button>
    </div>
    <div class="mb-6">
        <app-powered-by-footer></app-powered-by-footer>
    </div>
</div>
