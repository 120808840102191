import {Component, Input} from '@angular/core';
import {BusinessWCardComponent} from "../business-w-card/business-w-card.component";
import {SECTION_LIST_DISPLAY} from "../../common/nav-menu-list";
import {DatePipe} from "@angular/common";
import {DateFormatter, DateUtil} from "../../../../common/util/date.util";
import {WEBSITE_SECTION_TYPE} from "../../common/website-model/website-section.model";
import {CertificateAttributes} from "../../common/website-model/certificate.model";
import {WebsiteSectionSubCardComponent} from "../../website-section-sub-card/website-section-sub-card.component";
import {WebsiteSectionCardComponent} from "../../website-section-card/website-section-card.component";

@Component({
  selector: 'app-w-certificate',
  standalone: true,
  imports: [
    BusinessWCardComponent,
    DatePipe,
    WebsiteSectionSubCardComponent,
    WebsiteSectionCardComponent,
  ],
  templateUrl: './w-certificate.component.html',
  styleUrl: './w-certificate.component.scss'
})
export class WCertificateComponent {
  @Input() certificateAttributes: CertificateAttributes | any;
  DateUtil = DateUtil;
  DateFormatter = DateFormatter;
  certificationDate: Date;
  expirationDate: Date;
  SECTION_LIST_DISPLAY = SECTION_LIST_DISPLAY;
  WEBSITE_SECTION_TYPE = WEBSITE_SECTION_TYPE;

  constructor() {
  }

  getDateDifference(certificationDate: Date, expirationDate: Date): string {
    this.setStartEndDate(certificationDate, expirationDate);
    return this.DateUtil.getDifference(this.certificationDate, this.expirationDate);
  }

  setStartEndDate(certificationDate: Date, expirationDate: Date): void {
    this.certificationDate = certificationDate;
    this.expirationDate = expirationDate;
  }
}
