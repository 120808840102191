<div class="sidebar-container box-shadow" style="background-image: url('assets/resume/talenlio-background-image.png')">
  <div class="heading-container">
    <img [src]="websiteImageUrl || 'assets/website/default-website-logo.png'" class="rounded-[50%]"
         width="65"
         alt="">
    <div class="title text-4xl font-semibold leading-tight mt-5">{{ personalInformationAttributes?.fullName }}</div>
    <div
      class="sub-title text-base font-normal leading-normal tracking-tighter">{{ personalInformationAttributes?.jobTitle }}
    </div>
  </div>
  <div class="w-full">
    <div class="intro-title text-5xl font-semibold leading-tight">
      {{ headerSectionAttribute?.title || "Hi, Welcome to My Career Journey! 👋💙" }}
    </div>
    @if (headerSectionAttribute?.primaryButtonLink) {
      <div class="cta-container flex gap-1 mt-8 w-max cursor-pointer"
           [ngClass]="headerSectionAttribute?.primaryButtonLink ? 'pointer-events-auto' : 'pointer-events-none' "
           (click)="openUrl(headerSectionAttribute?.primaryButtonLink)">
        <div
          class="connect-cta font-poppins text-base font-medium leading-6 cursor-pointer">{{ headerSectionAttribute?.primaryButtonTitle }}
        </div>
        <div class="arrow-icon text-3xl ti ti-circle-arrow-up-right"></div>
      </div>
    }
  </div>

  <div class="side-bar-social-link-item flex gap-4 mt-10">
    @for (socialLink of socialLinkAttributes?.socialLinks; track socialLink?.id) {
      <div class="side-bar-social-link">
        <div class="side-bar-social-link-item">
          @switch (socialLink.socialLink) {
            @case (SOCIAL_LINK.WEBSITE) {
              <li class="text-2xl" [ngClass]="RESUME_ICON_LIST[SOCIAL_LINK.WEBSITE]"
                  (click)="openUrl(socialLink?.name)">
              </li>
            }
            @case (SOCIAL_LINK.LINKEDIN) {
              <li class="text-2xl" [ngClass]="RESUME_ICON_LIST[SOCIAL_LINK.LINKEDIN]"
                  (click)="openUrl(socialLink?.name)">
              </li>
            }
            @case (SOCIAL_LINK.GITHUB) {
              <li class="text-2xl" [ngClass]="RESUME_ICON_LIST[SOCIAL_LINK.GITHUB]"
                  (click)="openUrl(socialLink?.name)">
              </li>
            }
            @case (SOCIAL_LINK.DRIBBBLE) {
              <li class="text-2xl" [ngClass]="RESUME_ICON_LIST[SOCIAL_LINK.DRIBBBLE]"
                  (click)="openUrl(socialLink?.name)">
              </li>
            }
            @case (SOCIAL_LINK.BEHANCE) {
              <li class="text-2xl" [ngClass]="RESUME_ICON_LIST[SOCIAL_LINK.BEHANCE]"
                  (click)="openUrl(socialLink?.name)">
              </li>
            }
          }
        </div>
      </div>
    }
  </div>
</div>
