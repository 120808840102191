<ng-container>
    <div class="flex flex-row items-center justify-start resume-section-title-bottom">
        <div class="flex items-center justify-start">
            @if (!isClassicType) {
                <div class="ti ti-award text-sm sm:text-2xl"
                     [ngStyle]="{'color': resumeTheme?.iconColor}">
                </div>
            }
            <div class="resume-section-title"
                 [ngClass]="isClassicType ? 'pl-0' : 'pl-1' "
                 [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.primaryColor : resumeTheme?.twoColumnHighlightedHeadingText">
                {{ sectionTitle || RESUME_SECTION_TYPE_DISPLAY.SKILL | titlecase }}
            </div>
        </div>
    </div>
    <hr [style.border-color]="isClassicType ? classicLayoutTheme?.borderColor : resumeTheme.iconColor "
        class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
</ng-container>
@for (resumeHasSkillOutDto of resumeHasSkillOutDtos; let index = $index; track resumeHasSkillOutDto.id) {
  <div class="resume-section-content-between">
    <div class="w-full flex flex-col justify-center items-stretch">
      @if (resumeHasSkillOutDto?.title) {
        <div class="resume-section-container-title"
             [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.primaryColor : resumeTheme.twoColumnHighlightedPrimaryText">
          {{ resumeHasSkillOutDto?.title }}
        </div>
      }
      <div class="w-full flex flex-wrap justify-start flex-row items-center">
        @for (skill of resumeHasSkillOutDto?.skills; track skill) {
          <div class="resume-section-container-text mr-3 chip m-1"
               [ngClass]="{'border-solid border':isClassicType}"
               [style.border-color] = "classicLayoutTheme?.borderColor"
               [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme?.skillChipTextColor : resumeTheme?.highlightedChipText"
               [style.background]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme?.skillChipBackground : resumeTheme?.highlightedChipBackground">
            {{ skill }}
          </div>
        }
      </div>
    </div>
  </div>
  @if (index < resumeHasSkillOutDtos?.length - 1) {
    <hr class="w-full border-white-light resume-horizontal-line-below-distance"/>
  }
}
