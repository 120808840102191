import {Component, OnInit} from '@angular/core';
import {CompanyOutDto, CompanyService} from "../service/company.service";
import {NgClass, NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";
import {COMPANY_SIZE_DISPLAY} from "../enum/company.model";
import {JobComponent} from "./job/job.component";
import {LibCardSkeletonComponent} from "../../library/lib-card-skelton/lib-card-skeleton.component";
import {ActivatedRoute} from "@angular/router";
import {ROUTE} from "../../common/route-constants/route";
import {AboutUsComponent} from "./about-us/about-us.component";
import {PoweredByFooterComponent} from "../../components/powered-by-footer/powered-by-footer.component";
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {DriveComponent} from "./drive/drive.component";

@Component({
  selector: 'tal-company',
  standalone: true,
  imports: [
    NgIf,
    JobComponent,
    LibCardSkeletonComponent,
    NgForOf,
    AboutUsComponent,
    NgTemplateOutlet,
    NgClass,
    PoweredByFooterComponent,
    MatTabGroup,
    MatTab,
    DriveComponent
  ],
  templateUrl: './company.component.html',
  styleUrl: './company.component.scss'
})
export class CompanyComponent implements OnInit {
  companyOutDto: CompanyOutDto;
  defaultCoverImage = 'assets/images/cover-background.png';
  companyLogo = "assets/images/default-logo.png";
  companyUrl: string;
  protected readonly COMPANY_SIZE_DISPLAY = COMPANY_SIZE_DISPLAY;

  constructor(private activatedRoute: ActivatedRoute,
              private companyService: CompanyService) {
  }

  ngOnInit(): void {
    this.setCompanyUrl();
    this.setCompanyOutDto();
  }

  private setCompanyUrl(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const companyUrl = (params.get(ROUTE.URL_HANDLE));
      if (companyUrl) {
        this.companyUrl = companyUrl;
      }
    });
  }

  private setCompanyOutDto(): void {
    this.companyService.findByUrl(this.companyUrl).subscribe(companyOutDto => {
      this.companyOutDto = companyOutDto;
    });
  }
}
