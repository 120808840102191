import {Component, Input, OnChanges} from '@angular/core';
import {DatePipe, NgClass, NgStyle, NgTemplateOutlet, TitleCasePipe} from "@angular/common";
import {DateFormatter, DateUtil} from "../../../../common/util/date.util";
import {ResumeHasWorkExperienceOutDto} from "../../common/resume-section-model/work-experience.model";
import {SafeResourceUrl} from "@angular/platform-browser";
import {ClassicLayoutModel, ResumeThemeModel} from "../../common/theme/resume-theme.model";
import {LAYOUT_TYPE} from "../../common/layout-selection.model";
import {RESUME_SECTION_TYPE_DISPLAY} from "../../common/resume-section.model";
import {ResumeClassicLayoutUtil} from "../../common/theme/resume-classic-layout.util";

@Component({
  selector: 'app-r-work-experience',
  standalone: true,
  imports: [
    NgStyle,
    NgTemplateOutlet,
    DatePipe,
    NgClass,
    TitleCasePipe
  ],
  templateUrl: './r-work-experience.component.html',
  styleUrl: './r-work-experience.component.scss'
})
export class RWorkExperienceComponent implements OnChanges {
  @Input()
  resumeTheme: ResumeThemeModel;
  @Input()
  resumeHasWorkExperienceOutDtos: ResumeHasWorkExperienceOutDto[];
  @Input()
  layoutType: LAYOUT_TYPE;
  @Input()
  sectionTitle: string;
  @Input()
  isClassicType: boolean = false;
  dateUtil = DateUtil;
  DateFormatter = DateFormatter;
  classicLayoutTheme: ClassicLayoutModel = ResumeClassicLayoutUtil.getClassicLayoutTheme();
  companyLogoMap = new Map<number, File | SafeResourceUrl>();
  protected readonly LAYOUT_TYPE = LAYOUT_TYPE;
  protected readonly RESUME_SECTION_TYPE_DISPLAY = RESUME_SECTION_TYPE_DISPLAY;

  ngOnChanges(): void {
    this.initWorkExperience();
  }

  private initWorkExperience(): void {
    this.resumeHasWorkExperienceOutDtos?.forEach((experience, index) => {
      this.companyLogoMap.set(index, experience?.companyLogoAttachmentUrl);
    });
  }
}
