export class ResumeHasLanguageCommonDto {
  rowIndex: number;
  language: string; // TODO: it should be name
  languageProficiency: LANGUAGE_PROFICIENCY;
  sectionTitle?: string;
  title: string;
  isActive: boolean;
}

export class ResumeHasLanguageOutDto extends ResumeHasLanguageCommonDto {
  id: string;
  createdAt: Date;
  lastModifiedAt: Date;
}

export enum LANGUAGE_PROFICIENCY {
  NATIVE_SPEAKER = "NATIVE_SPEAKER",
  HIGHLY_PROFICIENT = "HIGHLY_PROFICIENT",
  VERY_GOOD_COMMAND = "VERY_GOOD_COMMAND",
  GOOD_KNOWLEDGE = "GOOD_KNOWLEDGE"
}

export enum LANGUAGE_PROFICIENCY_DISPLAY {
  NATIVE_SPEAKER = "Native Speaker",
  HIGHLY_PROFICIENT = "Highly Proficient",
  VERY_GOOD_COMMAND = "Very Good Command",
  GOOD_KNOWLEDGE = "Good Knowledge"
}
