<div id="{{WEBSITE_SECTION_TYPE.ACHIEVEMENT}}" class="achievement-section-container mt-20">
  <tal-website-section-card logo="assets/website/achievement-icon.png"
                            [title]="SECTION_LIST_DISPLAY.ACHIEVEMENT">
  </tal-website-section-card>

  @for (achievement of achievementAttributes?.achievements; let index = $index; track achievement?.id) {
    <div class="card-secondary-container">
      <app-website-section-sub-card [title]="achievement?.title"
                                    [url]="achievement?.link"
                                    [description]="achievement?.description">
      </app-website-section-sub-card>
    </div>
  }
</div>
