import {Component, OnDestroy, OnInit} from '@angular/core';
import {OneColumnResumeComponent} from "./one-column-resume/one-column-resume.component";
import {TwoColumnResumeComponent} from "./two-column-resume/two-column-resume.component";
import {PublicApiResumeService} from "./service/public-api-resume.service";
import {ResumeOutDto} from "./common/resume.model";
import {LAYOUT_TYPE} from "./common/layout-selection.model";
import {ActivatedRoute, Router} from "@angular/router";
import {ROUTE} from "../../common/route-constants/route";
import {PoweredByFooterComponent} from "../../components/powered-by-footer/powered-by-footer.component";
import {MetaTagService} from "../../service/meta-tag.service";
import {ResumeStore} from "../ai-resume/common/store/resume.store";


@Component({
  selector: 'app-resume',
  standalone: true,
  imports: [
    OneColumnResumeComponent, TwoColumnResumeComponent, PoweredByFooterComponent
  ],
  templateUrl: './resume.component.html',
  styleUrl: './resume.component.scss'
})

export class ResumeComponent implements OnInit, OnDestroy {
  resumeOutDto: ResumeOutDto;
  LAYOUT_TYPE = LAYOUT_TYPE;
  loading = true;
  isCandidateProfile = false;

  constructor(private resumeService: PublicApiResumeService,
              private router: Router,
              private route: ActivatedRoute,
              private resumeStore: ResumeStore,
              private metaTagService: MetaTagService) {
    if (this.router.url.startsWith(`/${ROUTE.CANDIDATE}`)) {
      this.isCandidateProfile = true;
    }
  }

  ngOnInit(): void {
    this.metaTagService.addResumeTags(this.getCurrentUrl());
    this.getResumeData();
  }

  ngOnDestroy(): void {
    this.metaTagService.removeTags();
  }

  private getCurrentUrl(): string {
    return window.location.href;
  }

  private getResumeData(): void {
    if (this.isCandidateProfile) {
      this.setResumeOutDtoFromCandidateId();
    } else {
      this.setResumeOutDtoFromResumeId();
    }
  }

  private setResumeOutDtoFromCandidateId(): void {
    let candidateId = this.route.snapshot.params[ROUTE.CANDIDATE_ID];
    this.resumeService.findResumeByCandidateId(candidateId).subscribe(candidateOutDto => {
        this.loading = false;
        this.resumeOutDto = candidateOutDto?.jobProfileOutDto?.resumeOutDto;
        this.resumeStore.publishAndGetUpdatedResume(this.resumeOutDto);
      }
    );
  }

  private setResumeOutDtoFromResumeId(): void {
    let resumeId = this.route.snapshot.params[ROUTE.RESUME_ID];
    let universityId = this.route.snapshot.params[ROUTE.UNIVERSITY_ID];
    this.resumeService.findPublicResume(resumeId, universityId).subscribe((resumeOutDto: ResumeOutDto) => {
        this.loading = false;
        this.resumeOutDto = resumeOutDto;
        this.resumeStore.publishAndGetUpdatedResume(this.resumeOutDto);
      },
      (error) => {
        this.router.navigate([ROUTE.PAGE_NOT_FOUND]);
      }
    );
  }
}
