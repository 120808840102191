import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DatePipe, NgClass} from "@angular/common";
import {DateFormatter, DateUtil} from "../../util/date.util";
import {PublicApiEmployerHasDriveOutDto} from "../../../employer/company/drive/service/employer-has-drive.service";
import {LinkUtil} from "../../util/link.util";

@Component({
  selector: 'tal-job-card',
  standalone: true,
  imports: [
    NgClass,
    DatePipe,
  ],
  templateUrl: './job-card.component.html',
  styleUrl: './job-card.component.scss'
})
export class JobCardComponent implements OnInit, OnChanges {
  @Input() logo: string;
  @Input() title: string;
  @Input() companyLink: string;
  @Input() companyName: string;
  @Input() location: string;
  @Input() level: string;
  @Input() placementDriveType: string;
  @Input() department: string;
  @Input() jobType: string;
  @Input() workType: string;
  @Input() remainingDays: number;
  @Input() expireAt: string;
  @Input() expired: string;
  @Input() websiteLink: string;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() allJobCount: number;
  @Input() isViwJobSectionVisible = false;
  @Input() isPlacementDriveJobExpired: boolean;
  @Input() publicApiEmployerHasDriveOutDto: PublicApiEmployerHasDriveOutDto;
  currentDate = new Date();
  protected readonly DateFormatter = DateFormatter;

  ngOnInit(): void {
    this.setDate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isPlacementDriveJobExpired = changes?.['isPlacementDriveJobExpired']?.currentValue;
  }

  visitWebsite(): void {
      LinkUtil.openLinkInNewTab(this.websiteLink);
  }

  private setDate(): void {
    const currentDate: string = this.currentDate.toISOString();
    this.remainingDays = DateUtil.dateDifference(this.endDate, currentDate);
  }
}
