import {Component, OnInit} from '@angular/core';
import {AboutUsComponent} from "../employer/company/about-us/about-us.component";
import {DriveComponent} from "../employer/company/drive/drive.component";
import {JobComponent} from "../employer/company/job/job.component";
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {NgIf} from "@angular/common";
import {PoweredByFooterComponent} from "../components/powered-by-footer/powered-by-footer.component";
import {
  PlacementDriveHasJobOutDto,
  PlacementDriveService,
  PublicApiUniversityHasDriveOutDto
} from "./service/placementDrive.service";
import {ROUTE} from "../common/route-constants/route";
import {ActivatedRoute, Router} from "@angular/router";
import {JobCardDeprecatedComponent} from "./job-card-deprecated/job-card-deprecated.component";
import {DateUtil} from "../common/util/date.util";
import {PLACEMENT_DRIVE_STATUS} from "./service/placement-drive.model";

@Component({
  selector: 'app-university',
  standalone: true,
  imports: [
    AboutUsComponent,
    DriveComponent,
    JobComponent,
    MatTab,
    MatTabGroup,
    NgIf,
    PoweredByFooterComponent,
    JobCardDeprecatedComponent,
  ],
  templateUrl: './university.component.html',
  styleUrl: './university.component.scss'
})
export class UniversityComponent implements OnInit {
  defaultBannerImage = "assets/images/cover-picture.png";
  defaultCompanyLogo = "assets/images/default-logo.png";
  publicApiUniversityHasDriveOutDto: PublicApiUniversityHasDriveOutDto;
  universityId: string;
  driveId: string;
  jobPostId: string;
  isZeroState: boolean;
  isPlacementDriveUnpublished: boolean;

  constructor(private router: Router,
              private placementDriveService: PlacementDriveService,
              private activatedRoute: ActivatedRoute) {
    this.setUniversityId();
    this.setDriveId();
  }

  ngOnInit(): void {
    this.setPlacementDriveOutDto();
  }

  setPlacementDriveOutDto(): void {
    this.placementDriveService.findPublicDriveJobs(this.universityId, this.driveId)
      .subscribe(publicApiUniversityHasDriveOutDto => {
        this.publicApiUniversityHasDriveOutDto = publicApiUniversityHasDriveOutDto;
        this.isZeroState = publicApiUniversityHasDriveOutDto?.placementDriveHasJobOutDtos?.length === 0;
        this.isPlacementDriveUnpublished = publicApiUniversityHasDriveOutDto?.placementDriveStatus === PLACEMENT_DRIVE_STATUS.UNPUBLISHED;
      });
  }

  navigateToJobPostDetail(placementDriveHasJobOutDto: PlacementDriveHasJobOutDto): void {
    if (!this.isPlacementDriveJobExpired(this.publicApiUniversityHasDriveOutDto)) {
      this.router.navigate([ROUTE.JOBS, placementDriveHasJobOutDto?.jobPostOutDto?.id], {
        relativeTo: this.activatedRoute
      });
    }
  }

  isPlacementDriveJobExpired(publicApiUniversityHasDriveOutDto: PublicApiUniversityHasDriveOutDto): boolean {
    const combinedDateTime = DateUtil
      .getCombinedDateAndTime(new Date(publicApiUniversityHasDriveOutDto?.toDate),
        publicApiUniversityHasDriveOutDto?.endTime);
    const currentDateTime = DateUtil.getCurrentDateTime();
    return combinedDateTime.isValid() && currentDateTime.isAfter(combinedDateTime);
  }

  private setUniversityId(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const universityId = params.get(ROUTE.UNIVERSITY_ID);
      if (universityId) {
        this.universityId = universityId;
      }
    });
  }

  private setDriveId(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const driveId = params.get(ROUTE.PLACEMENT_DRIVE_ID);
      if (driveId) {
        this.driveId = driveId;
      }
    });
  }
}
