export class LayoutSelectionModel {
  layoutType: LAYOUT_TYPE;
  imageUrl: string;
  activeImageUrl?: string;
}

export enum LAYOUT_TYPE {
  ONE_COLUMN = 'ONE_COLUMN',
  TWO_COLUMN = 'TWO_COLUMN'
}

export enum LAYOUT_TYPE_DISPLAY {
  TWO_COLUMN = 'Two',
  ONE_COLUMN = 'One'
}

export enum ATS_LAYOUT_TYPE {
  MODERN = 'MODERN',
  CLASSIC = 'CLASSIC'
}

export class AtsLayoutSelectionModel {
  atsLayoutType: ATS_LAYOUT_TYPE;
}

export enum ATS_LAYOUT_TYPE_DISPLAY {
  MODERN = 'Modern',
  CLASSIC = 'Classic'
}
