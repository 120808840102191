export enum RESUME_SECTION_TYPE {
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  SUMMARY = 'SUMMARY',
  SKILL = 'SKILL',
  SKILL_GROUP = 'SKILL_GROUP',
  WORK_EXPERIENCE = 'WORK_EXPERIENCE',
  PROJECTS = 'PROJECTS',
  EDUCATION = 'EDUCATION',
  CERTIFICATES = 'CERTIFICATES',
  ACHIEVEMENTS = 'ACHIEVEMENTS',
  HOBBIES = 'HOBBIES',
  SOCIAL_LINKS = 'SOCIAL_LINKS',
  LANGUAGES = 'LANGUAGES',
  REFERENCE = 'REFERENCE'
}

export enum RESUME_SECTION_TYPE_DISPLAY {
  PERSONAL_INFORMATION = 'Personal Information',
  SUMMARY = 'Summary',
  SKILL = 'Skills',
  SKILL_GROUP = 'Skill Group',
  WORK_EXPERIENCE = 'Work Experience',
  PROJECTS = 'Projects',
  EDUCATION = 'Education',
  CERTIFICATES = 'Certificates',
  ACHIEVEMENT = 'Achievements',
  HOBBIES = 'Hobbies',
  SOCIAL_LINK = 'Social links',
  LANGUAGE = 'Languages',
  REFERENCE = 'References'
}
