import {WebsiteSectionAttributes} from "./website-section.model";

export enum SOCIAL_LINK {
  BEHANCE = "BEHANCE",
  DRIBBBLE = "DRIBBBLE",
  LINKEDIN = "LINKEDIN",
  GITHUB = "GITHUB",
  WEBSITE = "WEBSITE"
}

export enum SOCIAL_LINK_DISPLAY {
  BEHANCE = "Behance",
  DRIBBBLE = "Dribbble",
  LINKEDIN = "LinkedIn",
  GITHUB = "Github",
  WEBSITE = "Website"
}

export class SocialLinkOutDto {
  id?: string;
  rowIndex: number;
  name: string;
  socialLink: SOCIAL_LINK;
}

export class SocialLinkAttributes extends WebsiteSectionAttributes {
}
