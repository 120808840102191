<div class="p-10 h-screen overflow-auto outer-container"
     style="background-image: url('../../assets/resume/talenlio-background-image.png'); background-position: center; background-size: cover;">
  <div class="job-card-detail overflow-hidden box-shadow rounded-3xl">
    <div class="job-card-banner w-full ex-object-fit op-top overflow-hidden">
      <img [src]="publicApiUniversityHasDriveOutDto?.universityCoverPictureOutDto?.url || defaultBannerImage"
           class="w-full h-full"
           alt="">
    </div>
    <div class="heading-container flex gap-5">
      <div class="company-logo overflow-hidden">
        <img class="w-full h-full"
             [src]="publicApiUniversityHasDriveOutDto?.universityLogoOutDto?.url || defaultCompanyLogo" alt="">
      </div>
      <div class="heading-text-container">
        <div class="company-heading-text">
          <h1 class="heading-title text-2xl font-medium leading-6 mb-1">
            {{ publicApiUniversityHasDriveOutDto?.name }}
          </h1>
        </div>
        <div class="company-location-container flex items-center justify-between">
          <div class="location-content">
            @if (publicApiUniversityHasDriveOutDto?.address) {
              <div class="location-text flex items-start gap-2 mt-3">
                <div class="ti ti-map-pin"></div>
                <div class="location-address">
                  {{ publicApiUniversityHasDriveOutDto?.address }}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
    <div>
      @if (isZeroState) {
        <div class="grid place-content-center text-center">
          <img class="ml-auto mr-auto" src="assets/images/astronaut-logo.png" alt="">
          <h1 class="text-2xl font-medium leading-6 mt-5">No Jobs available in this placement drive</h1>
        </div>
      } @else if (!isZeroState && !isPlacementDriveUnpublished) {
        <div class="drive-container p-12">
          <div class="text-2xl mb-5">Jobs</div>
          @for (placementDriveHasJobOutDto of publicApiUniversityHasDriveOutDto?.placementDriveHasJobOutDtos; track placementDriveHasJobOutDto?.id) {
            <div class="drive-outer-container rounded-xl" (click)="navigateToJobPostDetail(placementDriveHasJobOutDto)">
              <tal-job-card-deprecated [placementDriveHasJobOutDto]="placementDriveHasJobOutDto"
                                       [publicApiUniversityHasDriveOutDto]="publicApiUniversityHasDriveOutDto"
                                       [defaultCompanyLogo]="defaultCompanyLogo"
                                       [isPlacementDriveJobExpired]="isPlacementDriveJobExpired(publicApiUniversityHasDriveOutDto)">
              </tal-job-card-deprecated>
            </div>
          }
        </div>
      }
    </div>
    @if (isPlacementDriveUnpublished) {
      <div class="grid place-content-center text-center p-4">
        <img class="ml-auto mr-auto h-52" src="assets/images/astronaut-logo.png" alt="">
        <h1 class="text-2xl font-medium leading-6 mt-5">
          Currently, there are no jobs available in this placement drive
        </h1>
      </div>
    }
    <div>
    </div>
  </div>
  <div class="py-8">
    <app-powered-by-footer></app-powered-by-footer>
  </div>
</div>

