import {Injectable} from '@angular/core';
import {Meta} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class MetaTagService {

  constructor(private meta: Meta) {
  }

  removeTags(): void {
    this.meta.removeTag('name="description"');
    this.meta.removeTag('property="og:url"');
    this.meta.removeTag('property="og:type"');
    this.meta.removeTag('property="og:title"');
    this.meta.removeTag('property="og:description"');
    this.meta.removeTag('property="og:image"');
    this.meta.removeTag('name="twitter:card"');
    this.meta.removeTag('property="twitter:domain"');
    this.meta.removeTag('property="twitter:url"');
    this.meta.removeTag('name="twitter:title"');
    this.meta.removeTag('name="twitter:description"');
    this.meta.removeTag('name="twitter:image"');
  }

  addResumeTags(url: string): void {
    this.meta.addTag({name: 'description', content: "It's time to shine with Talenlio"});
    this.meta.addTag({property: 'og:url', content: url});
    this.meta.addTag({property: 'og:type', content: 'website'});
    this.meta.addTag({property: 'og:title', content: 'Checkout my awesome resume'});
    this.meta.addTag({property: 'og:description', content: "It's time to shine with Talenlio"});
    this.meta.addTag({
      property: 'og:image',
      content: 'https://uploads-ssl.webflow.com/62a7569eec80fe876c6582d8/64b7e24d827b3394df67a1f9_Talenlio.png'
    });
    this.meta.addTag({name: 'twitter:card', content: 'summary_large_image'});
    this.meta.addTag({property: 'twitter:domain', content: 'showcase.talenlio.com'});
    this.meta.addTag({property: 'twitter:url', content: url});
    this.meta.addTag({name: 'twitter:title', content: 'Checkout my awesome resume'});
    this.meta.addTag({name: 'twitter:description', content: "It's time to shine with Talenlio"});
    this.meta.addTag({
      name: 'twitter:image',
      content: 'https://uploads-ssl.webflow.com/62a7569eec80fe876c6582d8/64b7e24d827b3394df67a1f9_Talenlio.png'
    });
  }
}
