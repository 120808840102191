import {COVER_LETTER_SECTION_TYPE} from "./cover-letter.model";

export class SocialLink {
    name: string;
    socialLinkType: SOCIAL_LINK_TYPE;
}

export class SocialLinkAttributes {
    socialLinks: SocialLink[];
    sectionType: COVER_LETTER_SECTION_TYPE.SOCIAL_LINK;
}

export enum SOCIAL_LINK_TYPE {
    BEHANCE = 'BEHANCE',
    DRIBBBLE = 'DRIBBBLE',
    LINKEDIN = 'LINKEDIN',
    GITHUB = 'GITHUB',
    WEBSITE = 'WEBSITE'
}

export enum SOCIAL_LINK_TYPE_DISPLAY {
    BEHANCE = 'Behance',
    DRIBBBLE = 'Dribbble',
    LINKEDIN = 'Linkedin',
    GITHUB = 'Github',
    WEBSITE = 'Website'
}
