import {Component, Input} from '@angular/core';
import {DatePipe, NgClass, NgStyle, NgTemplateOutlet, TitleCasePipe} from "@angular/common";
import {ResumeHasEducationOutDto} from "../../common/resume-section-model/education.model";
import {ClassicLayoutModel, ResumeThemeModel} from "../../common/theme/resume-theme.model";
import {DateFormatter} from "../../../../common/util/date.util";
import {LAYOUT_TYPE} from "../../common/layout-selection.model";
import {RESUME_SECTION_TYPE_DISPLAY} from "../../common/resume-section.model";
import {ResumeClassicLayoutUtil} from "../../common/theme/resume-classic-layout.util";

@Component({
  selector: 'app-r-education',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgStyle,
    TitleCasePipe,
    DatePipe,
    NgClass
  ],
  templateUrl: './r-education.component.html',
  styleUrl: './r-education.component.scss'
})
export class REducationComponent {
  @Input()
  resumeTheme?: ResumeThemeModel;
  @Input()
  resumeHasEducationOutDtos: ResumeHasEducationOutDto[];
  @Input()
  layoutType: LAYOUT_TYPE;
  @Input()
  sectionTitle: string;
  @Input()
  isClassicType: boolean = false;
  classicLayoutTheme: ClassicLayoutModel = ResumeClassicLayoutUtil.getClassicLayoutTheme();
  DateFormatter = DateFormatter;
  LAYOUT_TYPE = LAYOUT_TYPE;
  protected readonly RESUME_SECTION_TYPE_DISPLAY = RESUME_SECTION_TYPE_DISPLAY;
}
