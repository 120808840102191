import {Component, Input} from '@angular/core';
import {BusinessWCardComponent} from "../business-w-card/business-w-card.component";
import {DateFormatter, DateUtil} from '../../../../common/util/date.util';
import {SECTION_LIST_DISPLAY} from "../../common/nav-menu-list";
import {WEBSITE_SECTION_TYPE} from "../../common/website-model/website-section.model";
import {ProjectAttributes} from "../../common/website-model/project-out-dto.model";
import {DatePipe} from "@angular/common";
import {WebsiteSectionSubCardComponent} from "../../website-section-sub-card/website-section-sub-card.component";
import {WebsiteSectionCardComponent} from "../../website-section-card/website-section-card.component";

@Component({
  selector: 'app-w-project',
  standalone: true,
  imports: [
    BusinessWCardComponent,
    DatePipe,
    WebsiteSectionSubCardComponent,
    WebsiteSectionCardComponent,
  ],
  templateUrl: './w-project.component.html',
  styleUrl: './w-project.component.scss'
})
export class WProjectComponent {
  @Input() projectAttributes: ProjectAttributes | any;
  workStartDate: Date | string;
  DateFormatter = DateFormatter;
  DateUtil = DateUtil;
  startDate: Date;
  endDate: Date;
  SECTION_LIST_DISPLAY = SECTION_LIST_DISPLAY;
  WEBSITE_SECTION_TYPE = WEBSITE_SECTION_TYPE;

  constructor() {
  }

  getDateDifference(startDate: Date, endDate: Date): string {
    this.setStartDateEndDate(startDate, endDate);
    return this.DateUtil.getDifference(startDate, endDate);
  }

  setStartDateEndDate(startDate: Date, endDate: Date): void {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
