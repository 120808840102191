import {Injectable} from '@angular/core';
import {HttpClientWrapperService} from "../../service/http-client-wrapper.service";
import {Observable} from "rxjs";
import {
  ATTACHMENT_TYPE,
  EMPLOYER_MEMBER_STATUS,
  EMPLOYER_MEMBER_TYPE,
  EMPLOYER_STATUS,
  FIELD_DATA_TYPE,
  HIRING_ROUND_STATUS,
  JOB_POST_STATUS,
  ROUND_TYPE,
  SOCIAL_LINK
} from "../enum/company.model";
import {CURRENCY_CODE} from "../enum/currency.util";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private companyPublicApiUrl = '/public/companies';

  constructor(private httpClientWrapperService: HttpClientWrapperService) {
  }

  findByUrl(companyUrl: string): Observable<PublicApiCompanyOutDto> {
    return this.httpClientWrapperService.get<PublicApiCompanyOutDto>(`${this.companyPublicApiUrl}/${companyUrl}`);
  }
}

export class CompanyCommonDto {
  name: string;
  websiteLink: string;
  urlHandle: string;
  address: string;
  featured: boolean;
}

export class CompanyOutDto extends CompanyCommonDto {
  id: string;
  logoOutDto: AttachmentOutDto;
  careerSiteOutDto: CareerSiteOutDto;
  lastModifiedAt: string;
  createdAt: string;
}

export class CompanyMinOutDto extends CompanyCommonDto {
  id: string;
  logoOutDto: AttachmentOutDto;
  lastModifiedAt: string;
  createdAt: string;
}

export class PublicApiCompanyOutDto extends CompanyCommonDto {
  id: string;
  logoOutDto: AttachmentOutDto;
  careerSiteOutDto: CareerSiteOutDto;
  jobPostOutDtos: JobPostOutDto[];
  lastModifiedAt: string;
  createdAt: string;
}

export class JobPostCommonDto {
  status?: JOB_POST_STATUS;
  applicationLink?: string;
  urlHandle?: string;
  responsibilities?: string;
  description?: string;
  requirements?: string;
  minSalary?: string;
  maxSalary?: string;
  currency?: CURRENCY_CODE;
  jobFeatured?: boolean;
  workType?: string;
}

export class JobPostOutDto extends JobPostCommonDto {
  id: string;
  externalJob: boolean;
  companyOutDto: CompanyOutDto;
  locationOutDto: LocationOutDto;
  departmentOutDto: DepartmentOutDto;
  jobTypeOutDto: JobTypeOutDto;
  jobTitleOutDto: JobTitleOutDto;
  jobLevelOutDto: JobLevelOutDto;
  jobPostHasDegreeOutDtos: JobPostHasDegreeOutDto[];
  jobPostHasSkillOutDtos: JobPostHasSkillOutDto[];
  jobPostHasApplicationFormOutDtos: JobPostHasApplicationFormOutDto[];
  jobPostHasHiringRoundOutDtos: JobPostHasHiringRoundOutDto[];
  jobPostHasHiringTeamOutDto: JobPostHasHiringTeamOutDto[];
  createdAt: string;
  lastModifiedAt: string;
}

export class JobPostMinOutDto extends JobPostCommonDto {
  id: string;
  externalJob: boolean;
  locationOutDto: LocationOutDto;
  departmentOutDto: DepartmentOutDto;
  jobTypeOutDto: JobTypeOutDto;
  jobTitleOutDto: JobTitleOutDto;
  jobLevelOutDto: JobLevelOutDto;
  lastModifiedAt: string;
  createdAt: string;
  companyOutDto: CompanyOutDto;
}

export class LocationCommonDto {
  city: string;
  countryCode?: number;
}

export class LocationOutDto extends LocationCommonDto {
  id: string;
}

export class DepartmentOutDto {
  id: string;
  title:string;
}

export class JobType {
  name: string;
}

export class JobTypeInDto extends JobType {
}

export class JobTypeOutDto extends JobType {
  id: string;
}

export class JobTitle {
  title: string;
}

export class JobTitleOutDto extends JobTitle {
  id: string;
}

export class JobLevel {
  title: string;
}

export class JobLevelOutDto extends JobLevel {
  id: string;
}

export class AttachmentOutDto {
  id: string;
  path: string;
  fileName: string;
  url: string;
  attachmentType: ATTACHMENT_TYPE;
}

export class AttachmentInDto {
  path: string;
  attachmentType: ATTACHMENT_TYPE;
  file: File;
}

export class CareerSiteCommonDto {
  urlHandle: string;
  headline: string;
  summary: string;
  founded: string;
  primaryColor: string;
  secondaryColor: string;
  textColor: string;
  subTextColor: string;
  perksAndBenefit: string;
  instagramLink: string;
  linkedinLink: string;
  youtubeLink: string;
  websiteLink: string;
  facebookLink: string;
  companySize: string;
  industry: string;
}

export class CareerSiteOutDto extends CareerSiteCommonDto {
  id: string;
  careerSiteHasContactPersonOutDto: CareerSiteHasContactPersonOutDto;
  coverPictureOutDto: AttachmentOutDto;
  faviconOutDto: AttachmentOutDto;
  locationOutDto: LocationOutDto;
}

export class CareerSiteHasContactPerson {
  profilePictureAttachmentId: string;
  role: string;
  workEmailAddress: string;
  workContactNumber: string;
  socialLink: string;
  socialLinkType: SOCIAL_LINK;
}


export class CareerSiteHasContactPersonOutDto extends CareerSiteHasContactPerson {
  id: string;
  contactPersonOutDto: ContactPersonOutDto;
  profilePictureOutDto: AttachmentOutDto;
}

export class ContactPersonCommonDto {
  name: string;
  emailAddress: string;
  contactNumber: string;
  address: string;
}

export class ContactPersonInDto extends ContactPersonCommonDto {
}

export class ContactPersonOutDto extends ContactPersonCommonDto {
  id: string;
}

export class Degree {
  title: string;
  specialization: string;
}

export class DegreeInDto extends Degree {
}

export class DegreeOutDto extends Degree {
  id: string;
}


export class JobPostHasDegreeOutDto {
  id: string;
  degreeOutDto: DegreeOutDto;
}

export class JobPostHasSkillOutDto {
  id: string;
  skillOutDto: SkillOutDto;
}

export class SkillDto {
  skill: string;
}

export class SkillInDto extends SkillDto {
}

export class SkillOutDto extends SkillDto {
  id: string;
}

export class JobPostHasApplicationFormDto {
}

export class JobPostHasApplicationFormInDto extends JobPostHasApplicationFormDto {
  applicationFieldId: string
}

export class JobPostHasApplicationFormOutDto extends JobPostHasApplicationFormDto {
  id: string;
  applicationFieldOutDto: ApplicationFieldOutDto;
  rowIndex: number;
}

export class ApplicationFieldDto {
  fieldName: string;
  fieldDataType: FIELD_DATA_TYPE;
  dataset: string;
  description: string;
  placeholder: string;
}

export class ApplicationFieldInDto extends ApplicationFieldDto {
}

export class ApplicationFieldOutDto extends ApplicationFieldDto {
  id: string;
}

export class JobPostHasHiringRound {
  status?: HIRING_ROUND_STATUS;
}

export class JobPostHasHiringRoundInDto extends JobPostHasHiringRound {
  hiringRoundId: string;
}

export class JobPostHasHiringRoundOutDto extends JobPostHasHiringRound {
  id: string;
  rowIndex: number;
  hiringRoundOutDto: HiringRoundOutDto;
}

export class JobPostHasHiringRoundMinOutDto {
  id: string;
  rowIndex: number;
  hiringRoundMinOutDto: HiringRoundMinOutDto;
}

export class HiringRound {
  name: string;
  roundType: ROUND_TYPE;
  candidatePreparationMaterial: string;
  employerPreparationMaterial: string;
  metadata: string;
  ctaDisplayText: string;
}

export class HiringRoundInDto extends HiringRound {
}

export class HiringRoundOutDto extends HiringRound {
  id: string;
  scorecardOutDtos: ScorecardOutDto[];
}

export class HiringRoundMinOutDto {
  id: string;
  name: string;
  roundType: ROUND_TYPE;
  metadata: string;
  ctaDisplayText: string;
}

export class Scorecard {
  name: string;
}

export class ScorecardInDto extends Scorecard {
}

export class ScorecardOutDto extends Scorecard {
  id: string;
  scorecardHasAttributeOutDtos: ScorecardHasAttributeOutDto[];
}

export class ScorecardHasAttribute {
  attributeName: string;
}

export class ScorecardHasAttributeInDto extends ScorecardHasAttribute {
}

export class ScorecardHasAttributeOutDto extends ScorecardHasAttribute {
  id: string;
}

export class JobPostHasHiringTeamCommonDto {
}


export class JobPostHasHiringTeamInDto extends JobPostHasHiringTeamCommonDto {
  employerMemberId: string
}

export class JobPostHasHiringTeamOutDto extends JobPostHasHiringTeamCommonDto {
  id: string
  employerHasMemberOutDto: EmployerHasMemberOutDto
}

export class EmployerHasMember {
  employerMemberType: EMPLOYER_MEMBER_TYPE;
}

export class EmployerHasMemberInDto extends EmployerHasMember {
  designation: string;
  contactPersonInDto: ContactPersonInDto;
}

export class EmployerHasMemberOutDto extends EmployerHasMember {
  id: string;
  designation: string;
  contactPersonOutDto: ContactPersonOutDto;
  employerMemberStatus: EMPLOYER_MEMBER_STATUS;
  employerMinOutDto: EmployerMinOutDto;
}

export class EmployerMinOutDto {
  id: string;
  name: string;
  address: string;
  websiteLink: string;
  employerStatus: EMPLOYER_STATUS;
  companyOutDto: CompanyMinOutDto;
  enableMatchedCandidate: boolean;
}
