export enum COMPANY_SIZE_DISPLAY {
  MICRO = '1 - 20',
  SMALL = '21 - 100',
  MEDIUM = '101 - 500',
  LARGE = '501 - 1,000',
  VERY_LARGE = '1,001 - 5,000',
  HUGE = '5,001 - 10,000',
  ENTERPRISE = '10,001+',
}

export enum WORK_TYPE_DISPLAY {
  HYBRID = 'Hybrid',
  REMOTE = 'Remote',
  IN_OFFICE = 'In Office'
}

export enum ATTACHMENT_TYPE {
  RESUME_PERSONAL_INFORMATION = 'RESUME_PERSONAL_INFORMATION',
  CAREER_PAGE_COVER_PICTURE = 'CAREER_PAGE_COVER_PICTURE',
  CAREER_PAGE_LOGO = 'CAREER_PAGE_LOGO',
  CAREER_PAGE_FAVICON = 'CAREER_PAGE_FAVICON',
  CAREER_SITE_CONTACT_PERSON_HAS_PROFILE_PICTURE = 'CAREER_SITE_CONTACT_PERSON_HAS_PROFILE_PICTURE',
  APPLICANT_HAS_DOCUMENT = 'APPLICANT_HAS_DOCUMENT',
  RESUME_BRANDING = 'RESUME_BRANDING',
  CANDIDATE_DOCUMENT = 'CANDIDATE_DOCUMENT',
  USER_PROFILE_IMAGE = 'USER_PROFILE_IMAGE',
  FORM_PAGE_COVER_PICTURE = 'FORM_PAGE_COVER_PICTURE',
  COVER_PICTURE = 'COVER_PICTURE',
  FORM_PAGE_LOGO = 'FORM_PAGE_LOGO'
}

export enum SOCIAL_LINK {
  BEHANCE = 'BEHANCE',
  DRIBBBLE = 'DRIBBBLE',
  LINKEDIN = 'LINKEDIN',
  GITHUB = 'GITHUB',
  WEBSITE = 'WEBSITE'
}

export enum JOB_POST_STATUS {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED'
}

export enum PLACEMENT_DRIVE_TYPE {
  WALK_IN = 'WALK_IN',
  ON_CAMPUS = 'ON_CAMPUS',
  OFF_CAMPUS = 'OFF_CAMPUS',
  VIRTUAL = 'VIRTUAL'
}

export enum PLACEMENT_DRIVE_TYPE_DISPLAY {
  WALK_IN = 'Walk in',
  ON_CAMPUS = 'On campus',
  OFF_CAMPUS = 'Off campus',
  VIRTUAL = 'Virtual'
}

export enum FIELD_DATA_TYPE {
  TEXT_FIELD = 'TEXT_FIELD',
  TEXT_AREA = 'TEXT_AREA',
  DATE_PICKER = 'DATE_PICKER',
  DATE_TIME_PICKER = 'DATE_TIME_PICKER',
  DROPDOWN = 'DROPDOWN',
  MULTI_CHOICE = 'MULTI_CHOICE',
  FILE_UPLOAD = 'FILE_UPLOAD'
}

export enum ROUND_TYPE {
  IN_PERSON = 'IN_PERSON',
  ONLINE_MEETING = 'ONLINE_MEETING',
  ASSESSMENT = 'ASSESSMENT',
  SCREENING = 'SCREENING'
}

export enum HIRING_ROUND_STATUS {
  IN_QUEUE = 'IN_QUEUE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  QUALIFIED = 'QUALIFIED',
  DISQUALIFIED = 'DISQUALIFIED',
  HIRED = 'HIRED', //deprecated
  REJECTED = 'REJECTED' //deprecated
}

export enum EMPLOYER_MEMBER_STATUS {
  PENDING_REQUEST = 'PENDING_REQUEST ',
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED'
}

export enum EMPLOYER_MEMBER_TYPE {
  OWNER = 'OWNER',
  MEMBER = 'MEMBER',
  ADMIN = 'ADMIN'
}

export enum EMPLOYER_STATUS {
  WAITLIST = 'WAITLIST',
  INVITATION_SENT = 'INVITATION_SENT',
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED'
}
