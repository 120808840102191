<div class="flex items-start justify-end"
     [ngClass]="isClassicType ? 'flex-col w-full' : 'flex-row'">
    @if (isClassicType) {
        <div class="section-title-right flex justify-start items-center resume-section-title-bottom"
             [ngStyle]="{'color': resumeTheme?.primaryColor}">
            <div class="font-semibold text-sm sm:text-base">
                {{ sectionTitle || RESUME_SECTION_TYPE_DISPLAY.SOCIAL_LINK | titlecase }}
            </div>
        </div>
        <hr [style.border-color]="isClassicType ? classicLayoutTheme?.borderColor : resumeTheme.iconColor "
            class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
    }
    <div [ngClass]="isClassicType ? 'resume-section-content-between' : 'flex'">
        @for (socialLink of socialLinks; track socialLink) {
            <div
                [ngClass]="isClassicType ? 'w-full text-xs sm:text-sm resume-section-content-between' : 'w-fit text-sm sm:text-xl'"
                [ngStyle]="{'color': resumeTheme?.iconColor}">
                @switch (socialLink?.socialLink) {
                    @case (SOCIAL_LINK.GITHUB) {
                        <div class="social-icon-bg cursor-pointer mr-1"
                             [ngClass]="isClassicType ? 'text-xs sm:text-sm w-full'  : RESUME_ICON_LIST[SOCIAL_LINK.GITHUB]"
                             (click)="openUrlInNewTab(socialLink?.name)">
                            @if (isClassicType) {
                                <span
                                    class="font-normal resume-section-container-title"> {{ SOCIAL_LINK_DISPLAY.GITHUB }}</span>
                                <span class="mr-2 font-normal text-xs sm:text-sm break-normal">:</span>
                                <span class="color-primary resume-section-container-text">Link</span>
                            }
                        </div>
                    }
                    @case (SOCIAL_LINK.LINKEDIN) {
                        <div class="social-icon-bg cursor-pointer mr-1"
                             [ngClass]="isClassicType ? 'text-xs sm:text-sm w-full' : RESUME_ICON_LIST[SOCIAL_LINK.LINKEDIN]"
                             (click)="openUrlInNewTab(socialLink?.name)">
                            @if (isClassicType) {
                                <span
                                    class="font-normal resume-section-container-title"> {{ SOCIAL_LINK_DISPLAY.LINKEDIN }}</span>
                                <span class="mr-2 font-normal text-xs sm:text-sm break-normal">:</span>
                                <span class="color-primary resume-section-container-text">Link</span>
                            }
                        </div>
                    }
                    @case (SOCIAL_LINK.DRIBBBLE) {
                        <div class="social-icon-bg cursor-pointer mr-1"
                             [ngClass]="isClassicType ? 'text-xs sm:text-sm w-full' : RESUME_ICON_LIST[SOCIAL_LINK.DRIBBBLE]"
                             (click)="openUrlInNewTab(socialLink?.name)">
                            @if (isClassicType) {
                                <span
                                    class="font-normal resume-section-container-title"> {{ SOCIAL_LINK_DISPLAY.DRIBBBLE }}</span>
                                <span class="mr-2 font-normal text-xs sm:text-sm break-normal">:</span>
                                <span class="color-primary resume-section-container-text">Link</span>
                            }
                        </div>
                    }
                    @case (SOCIAL_LINK.BEHANCE) {
                        <div class="social-icon-bg cursor-pointer mr-1"
                             [ngClass]="isClassicType ? 'text-xs sm:text-sm w-full' : RESUME_ICON_LIST[SOCIAL_LINK.BEHANCE]"
                             (click)="openUrlInNewTab(socialLink?.name)">
                            @if (isClassicType) {
                                <span
                                    class="font-normal resume-section-container-title"> {{ SOCIAL_LINK_DISPLAY.BEHANCE }}</span>
                                <span class="mr-2 font-normal text-xs sm:text-sm break-normal">:</span>
                                <span class="color-primary resume-section-container-text">Link</span>
                            }
                        </div>
                    }
                    @default {
                        <div class="social-icon-bg cursor-pointer mr-1"
                             [ngClass]="isClassicType ? 'text-xs sm:text-sm w-full' : RESUME_ICON_LIST[SOCIAL_LINK.WEBSITE]"
                             (click)="openUrlInNewTab(socialLink?.name)">
                            @if (isClassicType) {
                                <span
                                    class="font-normal resume-section-container-title"> {{ SOCIAL_LINK_DISPLAY.WEBSITE }}</span>
                                <span class="mr-2 font-normal text-xs sm:text-sm break-normal">:</span>
                                <span class="color-primary resume-section-container-text">Link</span>
                            }
                        </div>
                    }
                }
            </div>
        }
    </div>
</div>
