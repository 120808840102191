import {Component, Input} from '@angular/core';
import {SECTION_LIST_DISPLAY} from "../../common/nav-menu-list";
import {DatePipe} from "@angular/common";
import {BusinessWCardComponent} from "../business-w-card/business-w-card.component";
import {WEBSITE_SECTION_TYPE} from "../../common/website-model/website-section.model";
import {AchievementAttributes} from "../../common/website-model/achievement-out-dto.model";
import {DateFormatter, DateUtil} from "../../../../common/util/date.util";
import {WebsiteSectionCardComponent} from "../../website-section-card/website-section-card.component";
import {WebsiteSectionSubCardComponent} from "../../website-section-sub-card/website-section-sub-card.component";

@Component({
  selector: 'app-w-achievement',
  standalone: true,
  imports: [
    BusinessWCardComponent,
    DatePipe,
    WebsiteSectionCardComponent,
    WebsiteSectionSubCardComponent
  ],
  templateUrl: './w-achievement.component.html',
  styleUrl: './w-achievement.component.scss'
})
export class WAchievementComponent {
  @Input() achievementAttributes: AchievementAttributes;
  SECTION_LIST_DISPLAY = SECTION_LIST_DISPLAY;
  WEBSITE_SECTION_TYPE = WEBSITE_SECTION_TYPE;
  DateFormatter = DateFormatter;
  DateUtil = DateUtil;

  constructor() {
  }
}
