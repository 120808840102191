import {CurrencyPipe} from "@angular/common";

export class CurrencyUtil {

  static getCurrencySymbol(currency: CURRENCY_CODE): string {
    return CURRENCY_SYMBOL[currency];
  }

  static getFormattedCurrency(value: string, currencyCode: CURRENCY_CODE, currencyPipe: CurrencyPipe, digitInfo = ''): string {
    try {
      if (value !== '' && value != null) {
        return currencyPipe.transform(value, currencyCode, 'symbol', digitInfo,
          LOCALE_CODE[currencyCode]);
      } else {
        return '--';
      }
    } catch (exception) {
      return value;
    }
  }
}

export enum CURRENCY_CODE {
  INR = 'INR',
  USD = 'USD',
  EURO = 'EURO'
}

export enum CURRENCY_SYMBOL {
  INR = '₹',
  USD = '$'
}

export enum LOCALE_CODE {
  INR = 'en_IN',
  USD = 'en-US'
}
