import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClientWrapperService} from "../../../service/http-client-wrapper.service";
import {CoverLetterOutDto} from "../model/cover-letter/cover-letter.model";
import {RequestParam} from "../../../common/model/request-param";

@Injectable({
  providedIn: 'root'
})
export class CoverLetterService {
  private publicApiUrl = '/public';
  private coverLetterUrl = '/coverLetters';
  private urlHandle = 'urlHandle';
  private searchUrl = this.publicApiUrl + this.coverLetterUrl + '/search';

  constructor(private httpClientWrapperService: HttpClientWrapperService) {
  }

  findPublicCoverLetterByUrlHandle(urlHandle: string): Observable<CoverLetterOutDto> {
    return this.httpClientWrapperService.get<CoverLetterOutDto>(`${this.searchUrl}`, this.getRequestParams(urlHandle));
  }

  private getRequestParams(urlHandle: string): RequestParam[] {
    return [{key: this.urlHandle, value: urlHandle}];
  }
}
