import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {ResumeOutDto} from "../../../resume/common/resume.model";

@Injectable({
  providedIn: 'root'
})
export class ResumeStore {

  private _updatedResumeOutDto = new BehaviorSubject<ResumeOutDto>(null);
  public updatedResumeRequired: Observable<ResumeOutDto>;

  constructor() {
    this.updatedResumeRequired = this._updatedResumeOutDto.asObservable();
  }

  public publishAndGetUpdatedResume(resumeOutDto: ResumeOutDto): void {
    this._updatedResumeOutDto.next(resumeOutDto);
  }
}
