<div class="w-full h-screen cover-letter-outer-container p-4 overflow-y-auto">
  <div class="cover-letter-container box-shadow overflow-hidden m-auto">
    <div class="top-corners h-4"
         [ngStyle]="{'background-color': coverLetterTheme?.primaryColor}">
    </div>
    <div class="desktop-cover-letter"
         [ngStyle]="{'background-color': coverLetterTheme?.quaternaryColor}">
      @if (coverLetterOutDto?.socialLinkAttributes) {
        <div class="px-4 pt-4 flex justify-end items-center"
             [ngClass]="{'view-component-outer-container': !editor}">
          @for (socialLink of coverLetterOutDto?.socialLinkAttributes?.socialLinks; track socialLink) {
            <div [ngClass]="{'view-component-inner-container': !editor}">
              <div class="text-xl"
                   [ngStyle]="{'color': coverLetterTheme?.secondaryColor}">
                @switch (socialLink?.socialLinkType) {
                  @case (SOCIAL_LINK_TYPE.GITHUB) {
                    <div class="p-1 social-icon-bg ti ti-brand-github cursor-pointer"
                         (click)="openUrlInNewTab(socialLink?.name)">
                    </div>
                  }
                  @case (SOCIAL_LINK_TYPE.LINKEDIN) {
                    <div class="p-1 social-icon-bg ti ti-brand-linkedin cursor-pointer"
                         (click)="openUrlInNewTab(socialLink?.name)">
                    </div>
                  }
                  @case (SOCIAL_LINK_TYPE.BEHANCE) {
                    <div class="p-1 social-icon-bg ti ti-brand-behance cursor-pointer"
                         (click)="openUrlInNewTab(socialLink?.name)">
                    </div>
                  }
                  @case (SOCIAL_LINK_TYPE.DRIBBBLE) {
                    <div class="p-1 social-icon-bg ti ti-brand-dribbble-filled cursor-pointer"
                         (click)="openUrlInNewTab(socialLink?.name)">
                    </div>
                  }
                  @case (SOCIAL_LINK_TYPE.WEBSITE) {
                    <div class="p-1 social-icon-bg ti ti-world cursor-pointer"
                         (click)="openUrlInNewTab(socialLink?.name)">
                    </div>
                  }
                  @default {
                    <div class="p-1 social-icon-bg eva eva-globe cursor-pointer"
                         (click)="openUrlInNewTab(socialLink?.name)">
                    </div>
                  }
                }
              </div>
            </div>
          }
        </div>
      }
      <div class="flex flex-col justify-between items-stretch"
           [ngClass]="{'view-component-outer-container': !editor}">
        <div class="mx-6 justify-between items-center flex heading-container"
             [ngClass]="{'view-component-inner-container': !editor}">
          <div class="w-full">
            @if (coverLetterOutDto?.personalInformationAttributes?.name) {
              <div class="font-bold text-lg sm:text-3xl mb-1 capitalize"
                   [ngStyle]="{'color': coverLetterTheme?.ternaryColor}">{{ coverLetterOutDto?.personalInformationAttributes?.name }}
              </div>
            }
            @if (coverLetterOutDto?.personalInformationAttributes?.jobTitle) {
              <div class="font-medium text-base capitalize"
                   [ngStyle]="{'color': coverLetterTheme?.secondaryColor}">{{ coverLetterOutDto?.personalInformationAttributes?.jobTitle }}
              </div>
            }
          </div>
          <div>
            @if (coverLetterOutDto?.personalInformationAttributes?.phone) {
              <div class="flex items-center justify-end"
                   [ngStyle]="{'color': coverLetterTheme?.ternaryColor}">
                <div class="ti ti-phone text-base"></div>
                <div class="pl-1 sm:text-base text-sm">
                  {{ coverLetterOutDto?.personalInformationAttributes?.phone }}
                </div>
              </div>
            }
            @if (coverLetterOutDto?.personalInformationAttributes?.email) {
              <div class="flex justify-end items-center"
                   [ngStyle]="{'color': coverLetterTheme?.ternaryColor}">
                <div class="ti ti-mail sm:text-base text-sm"></div>
                <div class="pl-1 sm:text-base text-sm">
                  {{ coverLetterOutDto?.personalInformationAttributes?.email }}
                </div>
              </div>
            }
            <div style="word-wrap: anywhere;"
                 class="flex justify-end"
                 [ngStyle]="{'color': coverLetterTheme?.secondaryColor}">
              @if (coverLetterOutDto?.personalInformationAttributes?.city) {
                <div class="ti ti-map-pin text-base">
                </div>
              }
              <div class="pl-1 text-base capitalize">
                @if (coverLetterOutDto?.personalInformationAttributes?.city) {
                  {{ coverLetterOutDto?.personalInformationAttributes?.city }}
                }
                @if (coverLetterOutDto?.personalInformationAttributes?.city) {
                  <span>
                    , {{ coverLetterOutDto?.personalInformationAttributes?.country }}
                  </span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      @if (recipientAttributes) {
        <div class="personal-data p-6">
          <div [ngStyle]="{'color': coverLetterTheme?.ternaryColor}">
            To, <br> {{ recipientAttributes?.concernPerson }}
          </div>
          <div [ngStyle]="{'color': coverLetterTheme?.ternaryColor}">
            {{ recipientAttributes?.companyName }}, {{ recipientAttributes?.address }}
          </div>
        </div>
      }
      <div class="p-6 flex justify-center items-center"
           [ngStyle]="{'color': coverLetterTheme?.ternaryColor}"
           [ngClass]="{'view-component-outer-container': !editor}">
        <div class="w-full"
             [ngClass]="{'view-component-inner-container': !editor}">
          @if (coverLetterOutDto?.subjectAttributes?.letterSubject) {
            <div class="flex justify-start items-baseline">
              <div class="mr-2">Subject:</div>
              <div class="break-all"> {{ coverLetterOutDto?.subjectAttributes?.letterSubject }}</div>
            </div>
          }
        </div>
      </div>
      <div class="flex justify-start items-center"
           [ngClass]="{'view-component-outer-container': !editor}">
        <div [ngClass]="{'view-component-inner-container w-full': !editor}">
          @if (coverLetterOutDto?.greetingParagraphAttributes?.greeting) {
            <div class="px-6"
                 [ngStyle]="{'color': coverLetterTheme?.ternaryColor}">
              {{ coverLetterOutDto?.greetingParagraphAttributes?.greeting }}
            </div>
          }
          @if (coverLetterOutDto?.greetingParagraphAttributes?.openingParagraph) {
            <div class="px-3 opening-paragraph cover-letter-text">
              {{ coverLetterOutDto?.greetingParagraphAttributes?.openingParagraph }}
            </div>
          }
        </div>
      </div>
      @if (coverLetterOutDto?.bodyAttributes) {
        <div class="flex justify-start items-center"
             [ngClass]="{'view-component-outer-container': !editor}">
          <div [ngClass]="{'view-component-inner-container w-full': !editor}">
            @if (coverLetterOutDto?.bodyAttributes?.yourself) {
              <div class="px-3 cover-letter-text mt-2">
                {{ coverLetterOutDto?.bodyAttributes?.yourself }}
              </div>
            }
            @if (coverLetterOutDto?.bodyAttributes?.accomplishment) {
              <div class="px-3 cover-letter-text mt-2">
                {{ coverLetterOutDto?.bodyAttributes?.accomplishment }}
              </div>
            }
            @if (coverLetterOutDto?.bodyAttributes?.eligibility) {
              <div class="px-3 cover-letter-text mt-2">
                {{ coverLetterOutDto?.bodyAttributes?.eligibility }}
              </div>
            }
          </div>
        </div>
      }
      <div class="flex justify-start items-center"
           [ngClass]="{'view-component-outer-container': !editor}">
        <div [ngClass]="{'view-component-inner-container w-full': !editor}">
          @if (coverLetterOutDto?.closingMessageAttributes?.message) {
            <div class="px-3 cover-letter-text mt-2">
              {{ coverLetterOutDto?.closingMessageAttributes?.message }}
            </div>
          }
        </div>
      </div>
      <div class="flex justify-start items-center"
           [ngClass]="{'view-component-outer-container': !editor}">
        <div [ngClass]="{'view-component-inner-container w-full': !editor}">
          @if (coverLetterOutDto?.formalSalutationAttributes?.description) {
            <div class="px-3 cover-letter-text mt-2"
                 [innerHTML]="coverLetterOutDto?.formalSalutationAttributes?.description">
            </div>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="py-8">
    <app-powered-by-footer></app-powered-by-footer>
  </div>
</div>

