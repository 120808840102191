import {Component, Input, OnInit} from '@angular/core';
import {CompanyOutDto, CompanyService, JobPostOutDto} from "../../service/company.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ROUTE} from "../../../common/route-constants/route";
import {JobCardComponent} from "../../../common/component/job-card/job-card.component";
import {WORK_TYPE_DISPLAY} from "../../enum/company.model";

@Component({
  selector: 'app-job',
  standalone: true,
  imports: [
    JobCardComponent
  ],
  templateUrl: './job.component.html',
  styleUrl: './job.component.scss'
})
export class JobComponent implements OnInit {
  @Input() companyUrl: string;
  defaultCompanyLogo = "assets/images/default-logo.png";
  jobPostOutDtos: JobPostOutDto[];
  companyOutDto: CompanyOutDto;
  isZeroState: boolean;
  protected readonly WORK_TYPE_DISPLAY = WORK_TYPE_DISPLAY;

  constructor(private companyService: CompanyService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.setJobPostOutDto();
  }

  navigateToViewDetails(jobPostOutDto: JobPostOutDto): void {
    this.router.navigate([ROUTE.JOBS, jobPostOutDto?.id], {
      relativeTo: this.activatedRoute
    });
  }

  private setJobPostOutDto(): void {
    this.companyService.findByUrl(this.companyUrl).subscribe(publicApiCompanyOutDto => {
      this.jobPostOutDtos = publicApiCompanyOutDto?.jobPostOutDtos;
      this.isZeroState = publicApiCompanyOutDto?.jobPostOutDtos.length === 0;
    });
  }
}
