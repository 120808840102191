import moment from "moment/moment";
import {ResumeHasWorkExperienceOutDto} from "../../candidate/resume/common/resume-section-model/work-experience.model";

export class DateModel {
  seconds?: number;
  minutes?: number;
  hours?: number;
  days?: number;
  months?: number;
  years?: number;
  totalYears?: number;
  totalMonths?: number;
}

export class DateFormatter {
  public static MONTH_YEAR = 'MMM YYYY';
  public static MMM_DAY_YEAR = 'MMM d, y';
  public static FULL_DATE_AND_TIME = 'MMMM dd YYYY | hh:mm a';
  public static MONTH_APOSTROPHE_YEAR = "MMM'\''yy";
  public static YEAR_MONTH_DATE_HH_mm_ss = 'YYYY-MM-DD HH:mm:ss';
  public static DAY_MONTH_YEAR = 'dd.MM.YYYY';
  public static HH_mm_A = 'hh:mm A';
  public static DAY_MONTH_YEAR_HH_mm = 'dd.MM.YYYY hh:mm a';
}


export class DateUtil {
  static YEAR = 'yr';
  static YEARS = 'yrs';
  static MONTHS = 'm';

  public static getDifference(startDate: Date, endDate?: Date): string {
    const modifiedStartDate = startDate ? new Date(startDate) : null;
    const modifiedEndDate = endDate ? new Date(endDate) : null;
    const currentDate = new Date();
    if (modifiedStartDate && modifiedEndDate) {
      const dateModel = this.calculateYearsAndMonth(modifiedEndDate, modifiedStartDate, currentDate);
      return this.getMonthAndYear(dateModel);
    } else if (modifiedStartDate && currentDate) {
      const dateModel = this.calculateYearsAndMonth(modifiedEndDate, modifiedStartDate, currentDate);
      return this.getMonthAndYear(dateModel);
    } else {
      return '';
    }
  }

  public static getTotalYearsAndMonthCalculation(resumeHasWorkExperienceOutDtos: ResumeHasWorkExperienceOutDto[]): string {
    const yearAndMonthForExperience = this.getYearAndMonthForExperience(resumeHasWorkExperienceOutDtos);
    const dateModel = this.getTotalExperienceInYearsAndMonths(yearAndMonthForExperience);
    return this.validateTotalExperience(dateModel);
  }

  public static calculateWorkExperience(resumeHasWorkExperienceOutDtos: ResumeHasWorkExperienceOutDto[]): string {
    const yearAndMonthForExperience = this.getYearAndMonthForExperience(resumeHasWorkExperienceOutDtos);
    const dateModel = this.getTotalExperienceInYearsAndMonths(yearAndMonthForExperience);
    return this.validateTotalExperience(dateModel);
  }

  public static getRelativeTime(unixTimeStampInMs: string): string {
    if (unixTimeStampInMs) {
      return moment(Number(unixTimeStampInMs))?.fromNow();
    } else {
      return '--';
    }
  }

  public static dateDifference(startDate: string, currentDate: string): number {
    if (startDate && currentDate) {
      const fromDate = new Date(startDate);
      const toDate = new Date(currentDate);
      const differenceInMilliseconds = fromDate.getTime() - toDate.getTime();
      const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);
      return Math.ceil(differenceInDays);
    }
    return 0;
  }

  private static validateTotalExperience(dateModel: DateModel): string {
    let totalExperience: string = '';
    if (dateModel.totalYears > 0) {
      const yearConstant = dateModel.totalYears == 1 ? DateUtil.YEAR : DateUtil.YEARS;
      totalExperience = dateModel.totalYears + yearConstant;
      totalExperience = totalExperience + this.getTotalMonth(dateModel);
    } else if (dateModel.totalMonths > 0) {
      totalExperience = dateModel.totalMonths + DateUtil.MONTHS;
    }
    return totalExperience;
  }

  private static getTotalMonth(dateModel: DateModel): string {
    return dateModel.totalMonths > 0 ? (', ' + dateModel.totalMonths + DateUtil.MONTHS) : '';
  }

  private static calculateYearsAndMonth(endDate: Date, startDate: Date, currentDate: Date): DateModel {
    const startingDate = moment(startDate).startOf('month');
    const endingDate = endDate ? moment(endDate).endOf('month') : moment(currentDate).endOf('month');
    const months = Math.round(endingDate.diff(moment(startingDate), 'months', true)) % 12;
    let years = endingDate.diff(moment(startingDate), 'years');
    years = months == 0 ? years + 1 : years;
    return {
      years: years,
      months: months
    } as DateModel;
  }

  private static getMonthAndYear(dateModel: DateModel): string {
    const yearConstant = this.getYearConstant(dateModel);
    if (dateModel?.years < 1 && dateModel?.months >= 1) return `${Math.trunc(dateModel?.months)}${DateUtil.MONTHS}`;
    if (dateModel?.years >= 1 && dateModel?.months < 1) return `${Math.trunc(dateModel?.years)}${yearConstant}`;
    if (dateModel?.years >= 1 && dateModel?.months >= 1) return `${Math.trunc(dateModel?.years)}${yearConstant}, ${Math.trunc(dateModel?.months)}${DateUtil.MONTHS}`;
    return '';
  }

  private static getYearConstant(dateModel: DateModel): string {
    return dateModel.years == 1 ? DateUtil.YEAR : DateUtil.YEARS;
  }

  private static getTotalExperienceInYearsAndMonths(dateModel: DateModel): DateModel {
    let totalYears = dateModel.totalYears;
    let totalMonths = dateModel.totalMonths;
    totalYears = totalYears + Math.floor(totalMonths / 12);
    totalMonths = totalMonths % 12;
    return {
      totalYears: totalYears,
      totalMonths: totalMonths
    } as DateModel;
  }

  private static getYearAndMonthForExperience(resumeHasWorkExperienceOutDtos: ResumeHasWorkExperienceOutDto[]): DateModel {
    let totalYears = 0;
    let totalMonths = 0;
    if (resumeHasWorkExperienceOutDtos?.length > 0) {
      resumeHasWorkExperienceOutDtos?.forEach(experience => {
        const dateDifference = this.getDifference(experience?.startDate, experience?.endDate);
        const dateModel = this.separateYearsAndMonthsFromExperience(dateDifference);
        totalYears = totalYears + dateModel?.years;
        totalMonths = totalMonths + dateModel?.months;
      });
    }
    return {
      totalYears: totalYears,
      totalMonths: totalMonths
    } as DateModel;
  }

  private static separateYearsAndMonthsFromExperience(difference: string): DateModel {
    const years = this.getCount(difference, this.YEARS) || this.getCount(difference, this.YEAR);
    const months = this.getCount(difference, this.MONTHS);

    return {
      years: years,
      months: months
    } as DateModel;
  }

  private static getCount(difference: string, label: string): number {
    let count = 0;
    if (difference.includes(label)) {
      const index = difference.indexOf(label);
      count = parseInt(difference.substring(index - 2, index).trim());
    }
    return count;
  }

  public static getCurrentDateTime(): moment.Moment {
    return moment();
  }

  public static getCombinedDateAndTime(date: Date, time: string): moment.Moment {
    const toDate = moment(date, DateFormatter.YEAR_MONTH_DATE_HH_mm_ss);
    const endTimeObject = moment(time, DateFormatter.HH_mm_A);
    return moment(toDate).set({
      hour: endTimeObject.hour(),
      minute: endTimeObject.minute(),
      second: endTimeObject.second()
    });
  }
}
