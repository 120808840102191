import {COVER_LETTER_THEME_TYPE} from "../model/cover-letter/cover-letter.model";
import {CoverLetterThemeModel} from "../model/cover-letter-theme.model";

export class CoverLetterThemeUtil {

  private static colorPrimary: string = getComputedStyle(document.documentElement).getPropertyValue('--color-primary');
  private static colorTertiary: string = getComputedStyle(document.documentElement).getPropertyValue('--color-tertiary');
  private static colorWhite: string = getComputedStyle(document.documentElement).getPropertyValue('--color-white');

  public static getCoverLetterTheme(coverLetterThemeType: string): CoverLetterThemeModel {
    switch (coverLetterThemeType) {
      case COVER_LETTER_THEME_TYPE.SINGAPORE:
        return CoverLetterThemeUtil.getSingaporeTheme();
      case COVER_LETTER_THEME_TYPE.AMSTERDAM:
        return CoverLetterThemeUtil.getAmsterdamTheme();
      default:
        return CoverLetterThemeUtil.getAmsterdamTheme();
    }
  }

  public static getSingaporeTheme(): CoverLetterThemeModel {
    return {
      primaryColor: CoverLetterThemeUtil.colorPrimary,
      secondaryColor: CoverLetterThemeUtil.colorPrimary,
      ternaryColor: CoverLetterThemeUtil.colorTertiary,
      quaternaryColor: CoverLetterThemeUtil.colorWhite,
      footerImage: 'assets/cover-letter/theme-image/singapore-footer.svg',
    } as CoverLetterThemeModel;
  }

  public static getAmsterdamTheme(): CoverLetterThemeModel {
    return {
      primaryColor: CoverLetterThemeUtil.colorTertiary,
      secondaryColor: CoverLetterThemeUtil.colorPrimary,
      ternaryColor: CoverLetterThemeUtil.colorTertiary,
      quaternaryColor: CoverLetterThemeUtil.colorWhite,
      footerImage: 'assets/cover-letter/theme-image/singapore-footer.svg',
    } as CoverLetterThemeModel;
  }
}
