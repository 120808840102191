import {Component} from '@angular/core';
import {CONSTANTS} from "../../common/constant/constant";
import {NgOptimizedImage} from "@angular/common";
import {LinkUtil} from "../../common/util/link.util";

@Component({
  selector: 'app-powered-by-footer',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './powered-by-footer.component.html',
  styleUrl: './powered-by-footer.component.scss'
})
export class PoweredByFooterComponent {

  navigateToTalenlio(): void {
      LinkUtil.openLinkInNewTab(CONSTANTS.TALENLIO_LINK);
  }
}
