<div class="py-6 sm:px-0 h-screen overflow-y-scroll bg-cover bg-center"
     style="background-image: url('assets/resume/talenlio-background-image.png')">
  <ng-container>
    @if (loading) {
      <div class="m-auto resume-shimmer-effect">
        <div class="shimmer-background media"></div>
        <div class="p-8">
          <div class="shimmer-background title-line"></div>
          <div class="shimmer-background title-line end"></div>
          <div class="shimmer-background content-line mt-6"></div>
          <div class="shimmer-background content-line"></div>
          <div class="shimmer-background content-line"></div>
          <div class="shimmer-background content-line"></div>
          <div class="shimmer-background content-line end"></div>
        </div>
      </div>
    } @else {
      @if (resumeOutDto?.layoutType === LAYOUT_TYPE.ONE_COLUMN) {
        <app-one-column-resume [resumeOutDto]="resumeOutDto"
                               [resumeSectionDataOutDtos]="resumeOutDto?.resumeSectionDataOutDtos">
        </app-one-column-resume>
      } @else {
        <app-two-column-resume [resumeOutDto]="resumeOutDto"
                               [resumeSectionDataOutDtos]="resumeOutDto?.resumeSectionDataOutDtos">
        </app-two-column-resume>
      }
    }
  </ng-container>
</div>
