<div [style.background-color]="isClassicType ? classicLayoutTheme?.backgroundColor : resumeTheme?.pageBackground"
     class="box-shadow fade-in flex flex-col resume-container m-auto relative"
     [style.border-radius]="isClassicType ? '0px' : '14px'">
    <app-r-personal-information [resumeTheme]="resumeTheme"
                                [resumeHasPersonalInformationOutDto]="resumeHasPersonalInformationOutDto"
                                [profileImage]="profileImage"
                                [socialLinks]="resumeHasSocialLinkOutDtos"
                                [isClassicType]="isClassicType"
                                [layoutType]="resumeOutDto?.layoutType">
    </app-r-personal-information>
    <div class="p-4 pt-0 flex flex-col min-main-height">
        @if (resumeHasSummaryOutDto?.summary?.length > 0) {
            <app-r-summary [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.SUMMARY)"
                           [resumeTheme]="resumeTheme"
                           [isClassicType]="isClassicType"
                           [resumeHasSummaryOutDto]="resumeHasSummaryOutDto">
            </app-r-summary>
        }
        @if (resumeHasSocialLinkOutDtos?.length > 0 && isClassicType) {
            <app-r-social-link [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.SOCIAL_LINKS)"
                               [resumeTheme]="resumeTheme"
                               [socialLinks]="resumeHasSocialLinkOutDtos"
                               [isClassicType]="isClassicType"
                               [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.SOCIAL_LINKS)"></app-r-social-link>
        }
        @if (resumeHasEducationOutDtos?.length > 0) {
            <app-r-education [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.EDUCATION)"
                             [resumeTheme]="resumeTheme"
                             [resumeHasEducationOutDtos]="resumeHasEducationOutDtos"
                             [isClassicType]="isClassicType"
                             [layoutType]="resumeOutDto?.layoutType"
                             [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.EDUCATION)">

            </app-r-education>
        }
        @if (resumeHasCertificateOutDtos?.length > 0) {
            <app-r-certificate [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.CERTIFICATES)"
                               [resumeTheme]="resumeTheme"
                               [certificates]="resumeHasCertificateOutDtos"
                               [layoutType]="resumeOutDto?.layoutType"
                               [isClassicType]="isClassicType"
                               [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.CERTIFICATES)">
            </app-r-certificate>
        }
        @if (resumeHasWorkExperienceOutDtos?.length > 0) {
            <app-r-work-experience [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.WORK_EXPERIENCE)"
                                   [resumeTheme]="resumeTheme"
                                   [isClassicType]="isClassicType"
                                   [resumeHasWorkExperienceOutDtos]="resumeHasWorkExperienceOutDtos"
                                   [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.WORK_EXPERIENCE)"
                                   [layoutType]="resumeOutDto?.layoutType">
            </app-r-work-experience>
        }
        @if (resumeHasProjectOutDtos?.length > 0) {
            <app-r-project [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.PROJECTS)"
                           [resumeTheme]="resumeTheme"
                           [isClassicType]="isClassicType"
                           [resumeHasProjectOutDtos]="resumeHasProjectOutDtos"
                           [layoutType]="resumeOutDto?.layoutType"
                           [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.PROJECTS)">
            </app-r-project>
        }
        @if (resumeHasReferenceOutDtos?.length > 0) {
            <app-r-reference [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.REFERENCE)"
                             [resumeTheme]="resumeTheme"
                             [isClassicType]="isClassicType"
                             [resumeHasReferenceOutDtos]="resumeHasReferenceOutDtos"
                             [layoutType]="resumeOutDto?.layoutType"
                             [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.REFERENCE)">
            </app-r-reference>
        }
        @if (resumeHasSkillOutDtos?.length > 0) {
            <app-r-skill [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.SKILL_GROUP)"
                         [resumeTheme]="resumeTheme"
                         [isClassicType]="isClassicType"
                         [resumeHasSkillOutDtos]="resumeHasSkillOutDtos"
                         [layoutType]="resumeOutDto?.layoutType"
                         [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.SKILL_GROUP)">
            </app-r-skill>
        }
        @if (resumeHasHobbyOutDtos?.length > 0) {
            <app-r-hobby [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.HOBBIES)"
                         [resumeTheme]="resumeTheme"
                         [layoutType]="resumeOutDto?.layoutType"
                         [resumeHasHobbyOutDtos]="resumeHasHobbyOutDtos"
                         [isClassicType]="isClassicType"
                         [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.HOBBIES)">
            </app-r-hobby>
        }

        @if (resumeHasAchievementOutDtos?.length > 0) {
            <app-r-achievement [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.ACHIEVEMENTS)"
                               [resumeTheme]="resumeTheme"
                               [isClassicType]="isClassicType"
                               [resumeHasAchievementOutDtos]="resumeHasAchievementOutDtos"
                               [layoutType]="resumeOutDto?.layoutType"
                               [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.ACHIEVEMENTS)">
            </app-r-achievement>
        }
        @if (resumeHasLanguageOutDtos?.length > 0) {
            <app-r-language [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.LANGUAGES)"
                            [resumeTheme]="resumeTheme"
                            [isClassicType]="isClassicType"
                            [resumeHasLanguageOutDtos]="resumeHasLanguageOutDtos"
                            [layoutType]="resumeOutDto?.layoutType"
                            [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.LANGUAGES)">
            </app-r-language>
        }
    </div>
    <div class="w-full max-h-fit pt-2 pr-2">
        @if (!resumeOutDto?.removeBranding) {
            <app-r-resume-branding [resumeTheme]="resumeTheme"
                                   [isClassicType]="isClassicType"
                                   [removeBranding]="resumeOutDto?.removeBranding"
                                   [brandingImageUrl]="resumeOutDto?.brandingImageUrl">
            </app-r-resume-branding>
        }
    </div>
</div>
