import {Component, OnInit} from '@angular/core';
import {JobPostOutDto} from "../../../service/company.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ROUTE} from "../../../../common/route-constants/route";
import {PoweredByFooterComponent} from "../../../../components/powered-by-footer/powered-by-footer.component";
import {PublicApiJobPostService} from "../../../service/public-api-job-post.service";
import {CURRENCY_CODE, CurrencyUtil} from "../../../enum/currency.util";
import {CurrencyPipe} from "@angular/common";
import {CandidateLoggedInComponent} from "../../common/component/candidate-logged-in/candidate-logged-in.component";
import {TalDialogConfigModel} from "../../../../library/tal-open-dialog/common/model/tal-dialog-config-model";
import {TalDialogOpenService} from "../../../../library/tal-open-dialog/tal-dialog-open.service";
import {LinkUtil} from "../../../../common/util/link.util";

@Component({
  selector: 'tal-drive-detail-view',
  standalone: true,
  imports: [
    PoweredByFooterComponent
  ],
  templateUrl: './drive-detail-view.component.html',
  styleUrl: './drive-detail-view.component.scss',
  providers: [CurrencyPipe]
})
export class DriveDetailViewComponent implements OnInit {
  jobPostId: string;
  jobPostOutDto: JobPostOutDto;
  placementDriveId: string;
  companyUrl: string;
  bannerImage = "assets/images/cover-picture.png";
  defaultCompanyLogo = "assets/images/default-logo.png";

  constructor(private router: Router,
              private currencyPipe: CurrencyPipe,
              private activatedRoute: ActivatedRoute,
              private talDialogOpenService: TalDialogOpenService,
              private publicApiJobPostService: PublicApiJobPostService) {
    this.setPlacementDriveId();
    this.setCompanyUrl();
  }

  ngOnInit(): void {
    this.setJobPostOutDto();
  }

  getSalary(salary: string, currency: CURRENCY_CODE): string {
    return currency
      ? CurrencyUtil.getFormattedCurrency(salary, CURRENCY_CODE[currency], this.currencyPipe, '1.0-0')
      : salary;
  }

  goToAllJobs(): void {
    this.router.navigate([ROUTE.EMPLOYER + ROUTE.SLASH + ROUTE.COMPANY + ROUTE.SLASH + this.companyUrl]);
  }

  applyJob(jobPostOutDto: JobPostOutDto): void {
    this.openDialogToLoginOrSignup(jobPostOutDto);
  }

  visitWebsite(): void {
      LinkUtil.openLinkInNewTab(this.jobPostOutDto?.companyOutDto?.websiteLink)
  }

  private setCompanyUrl(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.companyUrl = params.get(ROUTE.URL_HANDLE);
    })
  }

  private openDialogToLoginOrSignup(jobPostOutDto: JobPostOutDto): void {
    this.talDialogOpenService.open(CandidateLoggedInComponent, {
      autoFocus: false,
      disableClose: false,
      width: "95%",
      data: {
        jobPostOutDto: jobPostOutDto,
        placementDriveId: this.placementDriveId
      }
    } as TalDialogConfigModel).afterClosed().subscribe(() => {
    });
  }

  private setPlacementDriveId(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.jobPostId = params.get(ROUTE.JOB_POST_ID);
    });
  }

  private setJobPostOutDto(): void {
    this.publicApiJobPostService.find(this.jobPostId).subscribe(jobPostOutDto => {
      this.jobPostOutDto = jobPostOutDto;
    });
  }
}
