import {Routes} from '@angular/router';
import {ResumeComponent} from "./candidate/resume/resume.component";
import {WebsiteComponent} from "./candidate/website/website.component";
import {CoverLetterComponent} from "./candidate/cover-letter/cover-letter.component";
import {ROUTE} from "./common/route-constants/route";
import {PageNotFoundComponent} from "./components/page-not-found/page-not-found.component";
import {CompanyComponent} from "./employer/company/company.component";
import {JobDetailComponent} from "./employer/company/job-detail/job-detail.component";
import {UniversityComponent} from "./university/university.component";
import {UniversityJobDetailComponent} from "./university/university-job-detail/university-job-detail.component";
import {DriveDetailViewComponent} from "./employer/company/drive/drive-detail-view/drive-detail-view.component";

export const routes: Routes = [
    {
        path: ROUTE.FIX_RESUME,
        loadChildren: () => import('./candidate/ai-resume/ai-resume.route').then(route => route.AI_RESUME_ROUTE)
    },
    {
        path: ROUTE.RESUME + ROUTE.SLASH + ROUTE.COLON + ROUTE.RESUME_ID,
        component: ResumeComponent
    },
    {
        path: ROUTE.CANDIDATE + ROUTE.SLASH + ROUTE.COLON + ROUTE.CANDIDATE_ID,
        component: ResumeComponent
    },
    {
        path: ROUTE.WEBSITE + ROUTE.SLASH + ROUTE.COLON + ROUTE.URL_HANDLE,
        component: WebsiteComponent
    },
    {
        path: ROUTE.COVER_LETTER + ROUTE.SLASH + ROUTE.COLON + ROUTE.URL_HANDLE,
        component: CoverLetterComponent
    },
    {
        path: ROUTE.EMPLOYER + ROUTE.SLASH + ROUTE.COMPANY + ROUTE.SLASH + ROUTE.COLON + ROUTE.URL_HANDLE,
        component: CompanyComponent,
    },
    {
        path: ROUTE.EMPLOYER + ROUTE.SLASH + ROUTE.COMPANY + ROUTE.SLASH + ROUTE.COLON + ROUTE.URL_HANDLE + ROUTE.SLASH
            + ROUTE.JOBS + ROUTE.SLASH + ROUTE.COLON + ROUTE.JOB_POST_ID,
        component: JobDetailComponent
    },
    {
        path: ROUTE.EMPLOYER + ROUTE.SLASH + ROUTE.COMPANY + ROUTE.SLASH + ROUTE.COLON + ROUTE.URL_HANDLE + ROUTE.SLASH
            + ROUTE.DRIVE_JOBS + ROUTE.SLASH + ROUTE.COLON + ROUTE.JOB_POST_ID,
        component: DriveDetailViewComponent
    },
    {
        path: ROUTE.UNIVERSITY + ROUTE.SLASH + ROUTE.COLON + ROUTE.UNIVERSITY_ID +
            ROUTE.SLASH + ROUTE.DRIVE + ROUTE.SLASH + ROUTE.COLON + ROUTE.PLACEMENT_DRIVE_ID,
        component: UniversityComponent
    },
    {
        path: ROUTE.UNIVERSITY + ROUTE.SLASH + ROUTE.COLON + ROUTE.UNIVERSITY_ID +
            ROUTE.SLASH + ROUTE.DRIVE + ROUTE.SLASH + ROUTE.COLON +
            ROUTE.PLACEMENT_DRIVE_ID + ROUTE.SLASH + ROUTE.JOBS +
            ROUTE.SLASH + ROUTE.COLON + ROUTE.JOB_POST_ID,
        component: UniversityJobDetailComponent
    },
    {
        path: ROUTE.PAGE_NOT_FOUND,
        component: PageNotFoundComponent
    },
    {
        path: '',
        redirectTo: ROUTE.FIX_RESUME,
        pathMatch: 'full'
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];
