<div class="flex flex-row justify-center gap-2 font-poppins">
  <div class="text-xs sm:text-sm">
    <div class="mb-3 text-center text-green">
      Powered by
    </div>
    <span class="cursor-pointer font-bold color-primary"
          (click)="navigateToTalenlio()">
    <img src="../../../assets/talenlio-logo.png"
         class="h-5 sm:h-6 w-auto"
         alt="talenlio-logo"/>
    </span>
  </div>
</div>
