const RESUME = 'r';
const FIX_RESUME = 'fix-resume';
const EMAIL = 'email';
const VERIFY_EMAIL = 'verify-email';
const WEBSITE = 'w';
const COVER_LETTER = 'cl';
const EMPLOYER = 'e';
const UNIVERSITY = 'u';
const COLON = ':';
const SLASH = '/';
const PAGE_NOT_FOUND = 'not-found';
const URL_HANDLE = 'url-handle';
const JOB_DETAIL = 'job-detail';
const JOB_POST = 'job-post';
const COMPANY = 'company';
const DRIVE = 'd';
const JOBS = 'jobs';
const DRIVE_JOBS = 'drive-jobs';
const PUBLIC = 'public';
const APPLY = 'apply';
const SUBDOMAIN = 'SUBDOMAIN';

//IDs
const JOB_POST_ID = 'jobPostId';
const RESUME_ID = 'resumeId';
const LOADING = 'loading';
const AI_RESUME_BUILDER = 'ai-resume-builder';
const CANDIDATE_ID = 'candidateId';
const DEFAULT = 'default';
const UNIVERSITY_ID = 'universityId';
const EMPLOYER_ID = 'employerId';
const PLACEMENT_DRIVE_ID = 'placementDriveId';


//authentication
const AUTH = 'auth';
const LOGIN = 'login';
const C = 'c';
const F = 'f';
const REGISTER = 'register';
const FULL_NAME = 'fullName';
const PHONE = 'phone';

export class ROUTE {
    public static RESUME = RESUME;
    public static FIX_RESUME = FIX_RESUME;
    public static EMAIL = EMAIL;
    public static VERIFY_EMAIL = VERIFY_EMAIL;
    public static FULL_NAME = FULL_NAME;
    public static PHONE = PHONE;
    public static RESUME_ID = RESUME_ID;
    public static LOADING = LOADING;
    public static AI_RESUME_BUILDER = AI_RESUME_BUILDER;
    public static WEBSITE = WEBSITE;
    public static COVER_LETTER = COVER_LETTER;
    public static EMPLOYER = EMPLOYER;
    public static UNIVERSITY = UNIVERSITY;
    public static COLON = COLON;
    public static SLASH = SLASH;
    public static SUBDOMAIN = SUBDOMAIN;
    public static PAGE_NOT_FOUND = PAGE_NOT_FOUND;
    public static URL_HANDLE = URL_HANDLE;
    public static JOB_POST_ID = JOB_POST_ID;
    public static UNIVERSITY_ID = UNIVERSITY_ID;
    public static EMPLOYER_ID = EMPLOYER_ID;
    public static PLACEMENT_DRIVE_ID = PLACEMENT_DRIVE_ID;
    public static JOB_DETAIL = JOB_DETAIL;
    public static JOB_POST = JOB_POST;
    public static COMPANY = COMPANY;
    public static DRIVE = DRIVE;
    public static CANDIDATE_ID = CANDIDATE_ID;
    public static DEFAULT = DEFAULT;
    //authentication
    public static AUTH = AUTH;
    public static LOGIN = LOGIN;
    public static REGISTER = REGISTER;
    //candidate
    public static CANDIDATE = C;
    public static FORM = F;
    static JOBS = JOBS;
    static DRIVE_JOBS = DRIVE_JOBS;
    static PUBLIC = PUBLIC;
    static APPLY = APPLY;
}
