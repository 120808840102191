export class ResumeHasHobbyCommonDto {
  rowIndex: number;
  hobby: string; // TODO: it should be name
  hobbyFrequency: HOBBY_FREQUENCY_TYPE;
  sectionTitle: string;
  isActive: boolean;
}
export class ResumeHasHobbyOutDto extends ResumeHasHobbyCommonDto {
  id: string;
  createdAt: Date;
  lastModifiedAt: Date;
}

export enum HOBBY_FREQUENCY_TYPE {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  OCCASIONALLY = 'OCCASIONALLY'
}

export enum HOBBY_FREQUENCY_TYPE_DISPLAY {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
  OCCASIONALLY = 'Occasionally'
}

