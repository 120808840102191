import {Component, Input} from '@angular/core';
import {DateFormatter} from "../../../common/util/date.util";
import {DatePipe} from "@angular/common";
import {LinkUtil} from "../../../common/util/link.util";

@Component({
  selector: 'app-website-section-sub-card',
  standalone: true,
  imports: [
    DatePipe
  ],
  templateUrl: './website-section-sub-card.component.html',
  styleUrl: './website-section-sub-card.component.scss'
})
export class WebsiteSectionSubCardComponent {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() present: string;
  @Input() location: string;
  @Input() dateDifference: string;
  @Input() description: string;
  @Input() url: string;
  DateFormatter = DateFormatter;

  openLink(): void {
      LinkUtil.openLinkInNewTab(this.url);
  }
}
