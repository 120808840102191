import {Injectable} from '@angular/core';
import {JobPostOutDto} from "./company.service";
import {Observable} from "rxjs";
import {HttpClientWrapperService} from "../../service/http-client-wrapper.service";

@Injectable({
  providedIn: 'root'
})
export class PublicApiJobPostService {
  private publicJobPostApiUrl = '/public/jobPosts';

  constructor(private httpClientWrapperService: HttpClientWrapperService) {
  }

  find(jobPostId: string): Observable<JobPostOutDto> {
    return this.httpClientWrapperService.get<JobPostOutDto>(`${this.publicJobPostApiUrl}/${jobPostId}`);
  }
}
