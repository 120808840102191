import {WEBSITE_SECTION_TYPE} from "./website-model/website-section.model";

export enum SECTION_LIST_DISPLAY {
  ABOUT = 'About Me',
  WORK_EXPERIENCE = 'Work Experiences',
  PROJECT = 'Projects',
  EDUCATION = 'Educations',
  SKILL = 'Skills',
  CERTIFICATE = 'Certificates',
  ACHIEVEMENT = 'Achievements',
  LANGUAGE = 'Languages',
  HOBBY = 'Hobbies',
  MORE = 'More',
  A_SHORT_OVERVIEW_OF_MY_PROFESSION = "A short overview of my professional career"
}

export class WebsiteMoreMenu {
  static getMoreMenu(): NavigationMenu[] {
    return [
      {
        displayText: 'About Me',
        sectionName: 'about-me-section',
        sectionType: WEBSITE_SECTION_TYPE.ABOUT_ME,
      },
      {
        displayText: 'Work Experience',
        sectionName: 'work-experience-section',
        sectionType: WEBSITE_SECTION_TYPE.WORK_EXPERIENCE,
      },
      {
        displayText: 'Education',
        sectionName: 'education-section',
        sectionType: WEBSITE_SECTION_TYPE.EDUCATION,
      },
      {
        displayText: 'Projects',
        sectionName: 'projects-section',
        sectionType: WEBSITE_SECTION_TYPE.PROJECT,
      },
      {
        displayText: 'Skill',
        sectionName: 'skill-section',
        sectionType: WEBSITE_SECTION_TYPE.SKILL,
      },
      {
        displayText: 'Certificates',
        sectionName: 'certificate-section',
        sectionType: WEBSITE_SECTION_TYPE.CERTIFICATE,
      },
      {
        displayText: 'Languages',
        sectionName: 'language-section',
        sectionType: WEBSITE_SECTION_TYPE.LANGUAGE,
      },
      {
        displayText: 'Achievements',
        sectionName: 'achievements-section',
        sectionType: WEBSITE_SECTION_TYPE.ACHIEVEMENT,
      },
      {
        displayText: 'Hobbies',
        sectionName: 'hobbies-section',
        sectionType: WEBSITE_SECTION_TYPE.HOBBY,
      }
    ];
  }
}

export interface NavigationMenu {
  displayText: string;
  sectionName: string;
  sectionType: WEBSITE_SECTION_TYPE
}
