<div class="p-10 h-screen overflow-auto outer-container"
     style="background-image: url('assets/resume/talenlio-background-image.png'); background-position: center; background-size: cover;">
  <div class="job-card-detail overflow-hidden box-shadow rounded-3xl">
    <div class="job-card-banner w-full ex-object-fit op-top overflow-hidden">
      <img class="w-full h-full" [src]="companyOutDto?.careerSiteOutDto?.coverPictureOutDto?.url || defaultCoverImage"
           [alt]="companyOutDto?.name">
    </div>
    <div class="heading-container flex gap-5">
      <div class="company-logo overflow-hidden">
        <img class="w-full h-full" [alt]="companyOutDto?.name" [src]="companyOutDto?.logoOutDto?.url || companyLogo">
      </div>
      <div class="heading-text-container">
        <div class="company-heading-text">
          <h1 class="heading-title text-2xl font-medium leading-6 mb-1">
            {{ companyOutDto?.name || '---' }}
          </h1>
        </div>
        <div class="company-location-container flex items-center justify-between">
          <div class="location-content">
            <div *ngIf="companyOutDto?.address" class="location-text flex items-start gap-2 mt-3">
              <div class="ti ti-map-pin"></div>
              <div class="location-address">
                {{ companyOutDto?.address }}
              </div>
            </div>
            <div *ngIf="companyOutDto?.careerSiteOutDto?.companySize"
                 class="location-text flex items-center gap-1 mt-3">
              <div class="ti ti-user"></div>
              <div class="team-size">
                {{ COMPANY_SIZE_DISPLAY[companyOutDto?.careerSiteOutDto?.companySize] }}
              </div>
            </div>
          </div>
          <div *ngIf="companyOutDto?.careerSiteOutDto?.websiteLink" class="company-link flex">
            <a [href]="companyOutDto?.websiteLink" target="_blank" class="company-link-text m-0">
              Visit Website
            </a>
            <div class="ti ti-arrow-up-right arrow-icon"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <mat-tab-group>
        <mat-tab label="Jobs">
          <app-job [companyUrl]="companyUrl"></app-job>
        </mat-tab>
        <!--        TODO for now hiding drives tab will remove commented code once placement drive is ready-->
        <!--        <mat-tab label="Drives">-->
        <!--         <tal-drive [companyId]="companyOutDto?.id"></tal-drive>-->
        <!--        </mat-tab>-->
        <mat-tab label="About Us">
          <app-about-us [companyUrl]="companyUrl"
                        [companyOutDto]="companyOutDto">
          </app-about-us>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div>
    </div>
  </div>
  <div class="py-8">
    <app-powered-by-footer></app-powered-by-footer>
  </div>
</div>
