import {ApplicationConfig, importProvidersFrom, isDevMode} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {HttpClientModule} from "@angular/common/http";
import {provideClientHydration} from '@angular/platform-browser';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideLottieOptions} from 'ngx-lottie';
import player from 'lottie-web';
import {provideServiceWorker} from '@angular/service-worker';
import {FIREBASE_PROVIDERS} from './common/constant/firebase.providers';


export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        importProvidersFrom(HttpClientModule),
        provideClientHydration(),
        provideAnimationsAsync(),
        FIREBASE_PROVIDERS,
        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
        }),
        provideLottieOptions({
            player: () => player
        }),
    ]
};

