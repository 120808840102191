import {Component, Input} from '@angular/core';
import {DatePipe, NgClass, NgStyle, NgTemplateOutlet, TitleCasePipe} from "@angular/common";
import {LAYOUT_TYPE} from "../../common/layout-selection.model";
import {DateFormatter} from "../../../../common/util/date.util";
import {ResumeHasCertificateOutDto} from "../../common/resume-section-model/certificate.model";
import {ClassicLayoutModel, ResumeThemeModel} from "../../common/theme/resume-theme.model";
import {RESUME_SECTION_TYPE_DISPLAY} from "../../common/resume-section.model";
import {ResumeClassicLayoutUtil} from "../../common/theme/resume-classic-layout.util";
import {LinkUtil} from "../../../../common/util/link.util";

@Component({
    selector: 'app-r-certificate',
    standalone: true,
    imports: [
        TitleCasePipe,
        NgTemplateOutlet,
        NgStyle,
        DatePipe,
        NgClass
    ],
    templateUrl: './r-certificate.component.html',
    styleUrl: './r-certificate.component.scss'
})
export class RCertificateComponent {
    @Input()
    resumeTheme?: ResumeThemeModel;
    @Input()
    certificates: ResumeHasCertificateOutDto[];
    @Input()
    layoutType: LAYOUT_TYPE;
    @Input()
    sectionTitle: string;
    @Input()
    isClassicType: boolean = false;
    LAYOUT_TYPE = LAYOUT_TYPE;
    DateFormatter = DateFormatter;
    classicLayoutTheme: ClassicLayoutModel = ResumeClassicLayoutUtil.getClassicLayoutTheme();
    protected readonly RESUME_SECTION_TYPE_DISPLAY = RESUME_SECTION_TYPE_DISPLAY;

    openUrlInNewTab(link: any): void {
        LinkUtil.openLinkInNewTab(link);
    }
}
