import {Component, Input, OnInit} from '@angular/core';
import {NgClass, NgStyle, NgTemplateOutlet, TitleCasePipe} from "@angular/common";
import {RSocialLinkComponent} from "../r-social-link/r-social-link.component";
import {CONTACT_NUMBER_TYPE_ICON, ResumeIconModel} from "../../common/resume-icon.model";
import {ClassicLayoutModel, ResumeThemeModel} from "../../common/theme/resume-theme.model";
import {ResumeHasPersonalInformationOutDto} from "../../common/resume-section-model/personal-information.model";
import {ResumeHasSocialLinkOutDto} from "../../common/resume-section-model/social-link.model";
import {LAYOUT_TYPE} from "../../common/layout-selection.model";
import {ResumeClassicLayoutUtil} from "../../common/theme/resume-classic-layout.util";


@Component({
  selector: 'app-r-personal-information',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgStyle,
    RSocialLinkComponent,
    NgClass,
    TitleCasePipe
  ],
  templateUrl: './r-personal-information.component.html',
  styleUrl: './r-personal-information.component.scss'
})
export class RPersonalInformationComponent implements OnInit {

  @Input() profileImage: string;
  @Input() resumeTheme: ResumeThemeModel;
  @Input() resumeHasPersonalInformationOutDto: ResumeHasPersonalInformationOutDto;
  @Input() socialLinks: ResumeHasSocialLinkOutDto[];
  @Input() layoutType: LAYOUT_TYPE;
  @Input() isClassicType: boolean = false;
  resumeIcon = ResumeIconModel.getIconForResume();
  classicLayoutTheme: ClassicLayoutModel = ResumeClassicLayoutUtil.getClassicLayoutTheme();
  LAYOUT_TYPE = LAYOUT_TYPE;
  protected readonly CONTACT_NUMBER_TYPE_ICON = CONTACT_NUMBER_TYPE_ICON;

  ngOnInit(): void {
  }
}
