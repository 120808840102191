import {Component, Input} from '@angular/core';
import {DateFormatter, DateUtil} from "../../../common/util/date.util";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'tal-website-section-card',
  standalone: true,
  imports: [
    DatePipe
  ],
  templateUrl: './website-section-card.component.html',
  styleUrl: './website-section-card.component.scss'
})
export class WebsiteSectionCardComponent {
  @Input() startDate: string;
  @Input() title: string;
  @Input() logo: string;
  @Input() duration: string;
  @Input() description: string;
  protected readonly DateUtil = DateUtil;
  protected readonly DateFormatter = DateFormatter;
}
