<div class="job-opening-container mt-5 p-4">
  @if (!isZeroState) {
    <div class="job-title text-2xl font-medium leading-6 p-2">Job opening</div>
    @for (jobPostOutDto of jobPostOutDtos; track jobPostOutDto) {
      <div (click)="navigateToViewDetails(jobPostOutDto)">
        <tal-job-card [logo]="jobPostOutDto?.companyOutDto?.logoOutDto?.url || defaultCompanyLogo"
                      [websiteLink]="jobPostOutDto?.companyOutDto?.websiteLink"
                      [title]="jobPostOutDto?.jobTitleOutDto?.title"
                      [companyName]="jobPostOutDto?.companyOutDto?.name"
                      [location]="jobPostOutDto?.locationOutDto?.city"
                      [workType]="WORK_TYPE_DISPLAY[jobPostOutDto?.workType]"
                      [jobType]="jobPostOutDto?.jobTypeOutDto?.name"
                      [level]="jobPostOutDto?.jobLevelOutDto?.title"
                      [isViwJobSectionVisible]="false">
        </tal-job-card>
      </div>
    }
  } @else {
    <div class="grid place-content-center text-center">
      <img class="ml-auto mr-auto" src="assets/images/astronaut-logo.png" alt="">
      <h1 class="text-2xl font-medium leading-6 mt-5">No Jobs Available!!!</h1>
    </div>
  }
</div>
