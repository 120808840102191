import {Component, Input, OnInit} from '@angular/core';
import {MatProgressBar} from "@angular/material/progress-bar";

@Component({
    selector: 'lib-progress-bar',
    standalone: true,
    imports: [
        MatProgressBar
    ],
    templateUrl: './lib-progress-bar.component.html',
    styleUrls: ['./lib-progress-bar.component.scss']
})
export class LibProgressBarComponent implements OnInit {

    @Input() isLoading = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}
