<ng-container>
    <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
        <div class="flex items-center justify-start">
            @if (!isClassicType) {
                <div class="ti ti-briefcase text-sm sm:text-2xl"
                     [ngStyle]="{'color': resumeTheme?.iconColor}">
                </div>
            }
            <div class="resume-section-title flex flex-row items-center"
                 [ngClass]="isClassicType ? 'pl-0' : 'pl-1' "
                 [style.color]="resumeTheme.primaryColor">
                {{ sectionTitle || RESUME_SECTION_TYPE_DISPLAY.WORK_EXPERIENCE | titlecase }}
                @if (resumeHasWorkExperienceOutDtos && !isClassicType) {
                    <span
                        [ngStyle]="{'background': resumeTheme?.skillChipBackground, 'color': resumeTheme?.skillChipTextColor}"
                        class="chip ml-1 px-2 font-normal text-xs sm:text-xs">
          {{ dateUtil?.calculateWorkExperience(resumeHasWorkExperienceOutDtos) }}
          </span>
                }
            </div>
        </div>
    </div>
    <hr [style.border-color]="isClassicType ? classicLayoutTheme?.borderColor : resumeTheme.iconColor "
        class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
</ng-container>
<div class="section-description w-full">
    @for (resumeHasWorkExperienceOutDto of resumeHasWorkExperienceOutDtos; let index = $index; track resumeHasWorkExperienceOutDto?.id) {
        <div class="w-full flex flex-col justify-center resume-section-content-between section-card">
            <div class="flex flex-row w-full gap-2">
                @if (!isClassicType) {
                    <div class="company-card">
                        <ng-container
                            [ngTemplateOutletContext]="{index: index}"
                            [ngTemplateOutlet]="resumeHasWorkExperienceOutDto?.companyLogoAttachmentUrl ? refCompanyPicture : refDefaultCompanyPicture">
                        </ng-container>
                    </div>
                }
                <div class="flex flex-col sm:flex-row justify-between items-start w-full">
                    <div class="flex flex-col justify-start items-start">
                        <div class="grid-cols-1 resume-section-container-title"
                             [style.color]="resumeTheme?.primaryColor">
                            {{ resumeHasWorkExperienceOutDto?.jobTitle }}
                        </div>
                        <div class="grid-cols-1 resume-section-container-text"
                             [style.color]="resumeTheme?.secondaryColor">
                            {{ resumeHasWorkExperienceOutDto?.companyName }}
                        </div>
                    </div>
                    <div class="flex items-start break-all"
                         [ngStyle]="{'color': resumeTheme?.secondaryColor}">
                        <div class="flex flex-col items-start sm:items-end">
                            @if (resumeHasWorkExperienceOutDto?.startDate) {
                                <div class="flex flex-row justify-start items-start gap-2">
                                    <div class="flex items-start resume-section-container-text whitespace-nowrap">
                                        {{ resumeHasWorkExperienceOutDto?.startDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                        -
                                        @if (resumeHasWorkExperienceOutDto?.workingHere || !resumeHasWorkExperienceOutDto?.endDate) {
                                            Present
                                        } @else {
                                            {{ resumeHasWorkExperienceOutDto?.endDate | date: DateFormatter.MONTH_APOSTROPHE_YEAR }}
                                        }
                                    </div>
                                    <span class="chip font-normal text-xxs sm:text-xs whitespace-nowrap px-2 ml-1"
                                          [ngStyle]="{'background': resumeTheme?.skillChipBackground, 'color': isClassicType ? resumeTheme?.secondaryColor: resumeTheme?.skillChipTextColor}">
                          {{ dateUtil.getDifference(resumeHasWorkExperienceOutDto?.startDate, resumeHasWorkExperienceOutDto?.endDate) }}
                    </span>
                                </div>
                            }
                            <div class="resume-section-container-text">
                                {{ resumeHasWorkExperienceOutDto?.location | titlecase }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="resume-section-container-description"
                 [innerHTML]="resumeHasWorkExperienceOutDto?.description"
                 [ngStyle]="{'color': resumeTheme?.primaryColor}">
            </div>
        </div>
    }
</div>
<ng-template #refCompanyPicture let-index="index">
    <img [src]="companyLogoMap.get(index)"
         alt="default-company-logo"
         class="rounded-[12px] object-cover h-12 w-12 max-w-12"/>
</ng-template>
<ng-template #refDefaultCompanyPicture>
    <img src="assets/resume/default-company-image.jpeg"
         alt="default-company-image"
         class="rounded-[12px] object-cover h-12 w-12 max-w-12"/>
</ng-template>
