import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgClass} from "@angular/common";
import {RESUME_THEME_TYPE, RESUME_THEME_TYPE_DISPLAY} from "../../../../resume/common/resume.model";
import {ResumeThemeUtil} from "../../../../resume/common/theme/resume-theme.util";

@Component({
    selector: 'resume-theme-indicator',
    standalone: true,
    imports: [
        NgClass
    ],
    templateUrl: './resume-theme-indicator.component.html',
    styleUrl: './resume-theme-indicator.component.scss'
})
export class ResumeThemeIndicatorComponent {
    @Input() resumeThemeType: RESUME_THEME_TYPE = RESUME_THEME_TYPE.ISTANBUL;
    @Output() selectedTheme = new EventEmitter<ResumeThemeSelectionModel>();
    resumeThemes: ResumeThemeSelectionModel[] = ResumeThemeUtil.getResumeThemeSelectionModels()

    constructor() {
    }

    onResumeThemeSelected(resumeThemeSelectionModel: ResumeThemeSelectionModel): void {
        this.selectedTheme.emit(resumeThemeSelectionModel);
    }
}

export class ResumeThemeSelectionModel {
    type: PORTFOLIO_TYPE;
    title: RESUME_THEME_TYPE_DISPLAY;
    themeType: RESUME_THEME_TYPE;
    backgroundColor?: string;
    headerColor?: string;
    isSelected?: boolean;
}

export enum PORTFOLIO_TYPE {
    RESUME = 'resume',
}
