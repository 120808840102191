import {Injectable} from '@angular/core';
import {ComponentType} from "@angular/cdk/overlay";
import {TalDialogConfigModel} from "./common/model/tal-dialog-config-model";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class TalDialogOpenService {

  constructor(private matDialog: MatDialog) {
  }

  open(component: ComponentType<any>, talDialogConfig?: TalDialogConfigModel): MatDialogRef<any> {
    let dialogConfigModel = this.mergeTalDialogConfig(talDialogConfig);
    return this.matDialog.open(component, {
      width: dialogConfigModel?.width,
      height: dialogConfigModel?.height,
      minWidth: dialogConfigModel?.minWidth,
      minHeight: dialogConfigModel?.minHeight,
      maxWidth: dialogConfigModel?.maxWidth,
      maxHeight: dialogConfigModel?.maxHeight,
      position: dialogConfigModel?.position,
      data: dialogConfigModel?.data,
      direction: dialogConfigModel?.direction,
      autoFocus: dialogConfigModel?.autoFocus,
      panelClass: dialogConfigModel?.panelClass,
      role: dialogConfigModel?.role,
      disableClose: dialogConfigModel?.disableClose
    });
  }

  private mergeTalDialogConfig(talDialogConfig: TalDialogConfigModel): TalDialogConfigModel {
    let defaultConfig = new TalDialogConfigModel();
    defaultConfig = {
      ...defaultConfig,
      ...talDialogConfig,
    };
    return defaultConfig;
  }
}
