<div id="{{WEBSITE_SECTION_TYPE.PROJECT}}" class="project-section-container mt-20">
  <tal-website-section-card logo="assets/website/project-icon.png"
                            [title]="SECTION_LIST_DISPLAY.PROJECT"
                            [startDate]="startDate | date: DateFormatter.MONTH_YEAR"
                            [duration]="DateUtil?.getTotalYearsAndMonthCalculation(projectAttributes?.projects)">
  </tal-website-section-card>
  @for (project of projectAttributes?.projects; let index = $index; track project) {
    <div class="card-secondary-container">
      <app-website-section-sub-card [title]="project?.projectTitle"
                                    [subTitle]="project?.workedFor"
                                    [url]="project?.projectLink"
                                    [present]="project?.workingHere"
                                    [startDate]="project?.startDate"
                                    [endDate]="project?.endDate"
                                    [dateDifference]="getDateDifference(project?.startDate , project?.endDate)"
                                    [location]="project?.region"
                                    [description]="project?.description">
      </app-website-section-sub-card>
    </div>
  }
</div>
