import {Component, Input} from '@angular/core';
import {DatePipe, NgClass, NgStyle, NgTemplateOutlet, TitleCasePipe} from "@angular/common";
import {ClassicLayoutModel, ResumeThemeModel} from "../../common/theme/resume-theme.model";
import {ResumeHasProjectOutDto} from "../../common/resume-section-model/project.model";
import {ResumeIconModel} from "../../common/resume-icon.model";
import {DateFormatter} from "../../../../common/util/date.util";
import {RESUME_SECTION_TYPE_DISPLAY} from "../../common/resume-section.model";
import {LAYOUT_TYPE} from "../../common/layout-selection.model";
import {ResumeClassicLayoutUtil} from "../../common/theme/resume-classic-layout.util";
import {LinkUtil} from "../../../../common/util/link.util";

@Component({
  selector: 'app-r-project',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgStyle,
    NgClass,
    DatePipe,
    TitleCasePipe
  ],
  templateUrl: './r-project.component.html',
  styleUrl: './r-project.component.scss'
})
export class RProjectComponent {
  @Input()
  resumeHasProjectOutDtos: ResumeHasProjectOutDto[];
  @Input()
  resumeTheme?: ResumeThemeModel;
  @Input()
  layoutType: LAYOUT_TYPE;
  @Input()
  sectionTitle: string;
  @Input()
  isClassicType: boolean = false;

  classicLayoutTheme: ClassicLayoutModel = ResumeClassicLayoutUtil.getClassicLayoutTheme();
  ResumeIconModel = ResumeIconModel;
  DateFormatter = DateFormatter;
  RESUME_SECTION_TYPE_DISPLAY = RESUME_SECTION_TYPE_DISPLAY;
  protected readonly LAYOUT_TYPE = LAYOUT_TYPE;

  openUrlInNewTab(projectLink: any): void {
      LinkUtil.openLinkInNewTab(projectLink);
  }
}
