import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SOCIAL_LINK} from "../../../resume/common/social-link.model";
import {RESUME_ICON_LIST} from "../../../resume/common/resume-section-model/resume.model";
import {NgClass} from "@angular/common";
import {PersonalInformationAttributes} from "../../common/website-model/personal-information.model";
import {SocialLinkAttributes} from "../../common/website-model/social-link-out.dto";
import {HeaderSectionAttribute} from '../../common/website-model/header.model';
import {LinkUtil} from "../../../../common/util/link.util";

@Component({
  selector: 'app-w-sidebar',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './w-sidebar.component.html',
  styleUrl: './w-sidebar.component.scss'
})
export class WSidebarComponent implements OnInit, OnChanges {
  @Input() personalInformationAttributes: PersonalInformationAttributes;
  @Input() socialLinkAttributes: SocialLinkAttributes;
  @Input() headerSectionAttribute: HeaderSectionAttribute;
  websiteImageUrl: string | any;
  SOCIAL_LINK = SOCIAL_LINK;
  RESUME_ICON_LIST = RESUME_ICON_LIST;

  ngOnChanges(changes: SimpleChanges): void {
    this.personalInformationAttributes = changes?.['personalInformationAttributes']?.currentValue;
    if (this.personalInformationAttributes) {
      this.isWebsiteImage();
    }
  }

  ngOnInit(): void {
  }

  openUrl(url: string): void {
      LinkUtil.openLinkInNewTab(url);
  }

  private isWebsiteImage(): void {
    this.personalInformationAttributes?.imageUrl ? this.setWebsitePicture() : null;
  }

  private setWebsitePicture(): void {
    this.websiteImageUrl = this.personalInformationAttributes?.imageUrl;
  }
}
