<div class="mb-2 text-base text-heading-light font-medium">Themes</div>
<div class="grid grid-cols-4 gap-1">
    @for (resumeTheme of resumeThemes; track resumeTheme?.themeType) {
        <div class="w-max items-center m-auto">
            <div class="p-1 resume-theme-outer-container w-max m-auto cursor-pointer"
                 [ngClass]="{'inactive-button-layout-button':resumeTheme.themeType !== resumeThemeType,'active-button-layout-button':resumeTheme.themeType === resumeThemeType}"
                 (click)="onResumeThemeSelected(resumeTheme)">
                <div class="flex items-center border-stroke rounded-lg">
                    <div class="h-10 w-6 rounded-l-lg"
                         [style.background]="resumeTheme?.headerColor">
                    </div>
                    <div class="h-10 w-6 rounded-r-lg">
                        <div class="h-1 w-3 my-1 ml-0.5 rounded-full border-stroke"
                             [style.background]="resumeTheme?.backgroundColor">
                        </div>
                        <div class="h-1 w-4 mb-2 ml-0.5 rounded-full border-stroke"
                             [style.background]="resumeTheme?.backgroundColor">
                        </div>
                        <div class="h-1 w-1.5 my-1 ml-0.5 rounded-full border-stroke"
                             [style.background]="resumeTheme?.backgroundColor">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
</div>
