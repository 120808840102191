import {Component, OnInit} from '@angular/core';
import {PoweredByFooterComponent} from "../../components/powered-by-footer/powered-by-footer.component";
import {ActivatedRoute, Router} from "@angular/router";
import {ROUTE} from "../../common/route-constants/route";
import {PublicApiJobPostService} from "../../employer/service/public-api-job-post.service";
import {JobPostOutDto} from "../../employer/service/company.service";
import {CURRENCY_CODE, CurrencyUtil} from "../../employer/enum/currency.util";
import {CurrencyPipe} from "@angular/common";
import {
  CandidateLoggedInComponent
} from "../../employer/company/common/component/candidate-logged-in/candidate-logged-in.component";
import {TalDialogConfigModel} from "../../library/tal-open-dialog/common/model/tal-dialog-config-model";
import {TalDialogOpenService} from "../../library/tal-open-dialog/tal-dialog-open.service";
import {LinkUtil} from "../../common/util/link.util";

@Component({
  selector: 'tal-university-job-detail',
  standalone: true,
  imports: [
    PoweredByFooterComponent
  ],
  templateUrl: './university-job-detail.component.html',
  styleUrl: './university-job-detail.component.scss',
  providers: [CurrencyPipe]
})
export class UniversityJobDetailComponent implements OnInit {
  bannerImage = "assets/images/cover-picture.png";
  defaultCompanyLogo = "assets/images/default-logo.png";
  universityId: string;
  placementDriveId: string;
  jobPostId: string;
  jobPostOutDto: JobPostOutDto;

  constructor(private currencyPipe: CurrencyPipe,
              private talDialogOpenService: TalDialogOpenService,
              private router: Router,
              private publicApiJobPostService: PublicApiJobPostService,
              private activatedRoute: ActivatedRoute) {
    this.setUniversityId();
    this.setPlacementDriveId();
    this.setJobPostId();
  }

  ngOnInit(): void {
    this.setJobDetailOutDto();
  }

  getSalary(salary: string, currency: CURRENCY_CODE): string {
    return currency
      ? CurrencyUtil.getFormattedCurrency(salary, CURRENCY_CODE[currency], this.currencyPipe, '1.0-0')
      : salary;
  }

  goToAllJobs(): void {
    this.router.navigate([ROUTE.UNIVERSITY + ROUTE.SLASH + this.universityId +
    ROUTE.SLASH + ROUTE.DRIVE + ROUTE.SLASH + this.placementDriveId]);
  }

  applyJob(jobPostOutDto: JobPostOutDto): void {
    this.openDialogToLoginOrSignup(jobPostOutDto);
  }

  visitWebsite(): void {
      LinkUtil.openLinkInNewTab(this.jobPostOutDto?.companyOutDto?.websiteLink);
  }

  private openDialogToLoginOrSignup(jobPostOutDto: JobPostOutDto): void {
    this.talDialogOpenService.open(CandidateLoggedInComponent, {
      autoFocus: false,
      disableClose: false,
      width: "95%",
      data: {
        jobPostOutDto: jobPostOutDto,
        placementDriveId: this.placementDriveId
      }
    } as TalDialogConfigModel).afterClosed().subscribe(() => {
    });
  }

  private setUniversityId(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const universityId = params.get(ROUTE.UNIVERSITY_ID);
      if (universityId) {
        this.universityId = universityId;
      }
    });
  }

  private setPlacementDriveId(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const placementDriveId = params.get(ROUTE.PLACEMENT_DRIVE_ID);
      if (placementDriveId) {
        this.placementDriveId = placementDriveId;
      }
    });
  }

  private setJobDetailOutDto(): void {
    if (this.jobPostId) {
      this.publicApiJobPostService.find(this.jobPostId).subscribe(jobPostOutDto => {
        this.jobPostOutDto = jobPostOutDto;
      });
    }
  }

  private setJobPostId(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const jobPostId = params.get(ROUTE.JOB_POST_ID);
      if (jobPostId) {
        this.jobPostId = jobPostId;
      }
    });
  }
}
