import {Component, Input} from '@angular/core';
import {NgClass, NgStyle, NgTemplateOutlet, TitleCasePipe} from "@angular/common";
import {ClassicLayoutModel, ResumeThemeModel} from "../../common/theme/resume-theme.model";
import {ResumeIconModel} from "../../common/resume-icon.model";
import {LAYOUT_TYPE} from "../../common/layout-selection.model";
import {ResumeHasAchievementOutDto} from "../../common/resume-section-model/achievement.model";
import {RESUME_SECTION_TYPE_DISPLAY} from "../../common/resume-section.model";
import {ResumeClassicLayoutUtil} from "../../common/theme/resume-classic-layout.util";
import {LinkUtil} from "../../../../common/util/link.util";

@Component({
    selector: 'app-r-achievement',
    standalone: true,
    imports: [
        NgStyle,
        NgTemplateOutlet,
        NgClass,
        TitleCasePipe
    ],
    templateUrl: './r-achievement.component.html',
    styleUrl: './r-achievement.component.scss'
})
export class RAchievementComponent {
    @Input()
    resumeTheme?: ResumeThemeModel;
    @Input()
    resumeHasAchievementOutDtos: ResumeHasAchievementOutDto[];
    @Input()
    layoutType: LAYOUT_TYPE;
    @Input()
    sectionTitle: string;
    @Input()
    isClassicType: boolean = false;

    ResumeIconModel = ResumeIconModel;
    protected readonly LAYOUT_TYPE = LAYOUT_TYPE;
    protected readonly RESUME_SECTION_TYPE_DISPLAY = RESUME_SECTION_TYPE_DISPLAY;
    classicLayoutTheme: ClassicLayoutModel = ResumeClassicLayoutUtil.getClassicLayoutTheme();

    openLinkInNewTab(link: any): void {
        LinkUtil.openLinkInNewTab(link);
    }
}
