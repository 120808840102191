import {Direction} from "@angular/cdk/bidi";
import {DialogPosition, DialogRole} from "@angular/material/dialog";

export class TalDialogConfigModel {
  disableClose: boolean = true;
  panelClass?: string | string[];
  role?: DialogRole;
  width: string = '50%';
  height?: string;
  minWidth?: string;
  minHeight?: string;
  maxWidth?: string;
  maxHeight?: string;
  position?: DialogPosition;
  data: any = null;
  direction?: Direction;
  autoFocus: boolean = true;
}
