<ng-container>
  <div class="section-title-right flex-row justify-start items-center resume-section-title-bottom">
    <div class="flex items-center justify-start">
      @if (!isClassicType) {
        <div class="ti ti-headphones text-sm sm:text-2xl"
             [ngStyle]="{'color': resumeTheme.iconColor}"></div>
      }
      <div class="resume-section-title"
           [ngClass]="isClassicType ? 'pl-0' : 'pl-1'"
           [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.primaryColor : resumeTheme.twoColumnHighlightedHeadingText">
        {{ sectionTitle || RESUME_SECTION_TYPE_DISPLAY.HOBBIES | titlecase }}
      </div>
    </div>
  </div>
  <hr [style.border-color]="isClassicType ? classicLayoutTheme?.borderColor : resumeTheme.iconColor"
      class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
</ng-container>
<div class="w-full grid"
     [ngClass]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? 'grid-cols-2 gap-2 sm:grid-cols-3': 'grid-cols-1'">
  @for (resumeHasHobbyOutDto of resumeHasHobbyOutDtos; let index = $index; track resumeHasHobbyOutDto?.id) {
    <div [ngClass]="{'resume-section-content-between':layoutType === LAYOUT_TYPE.TWO_COLUMN }">
      <div class="h-full flex flex-col items-start justify-start"
           [ngClass]="{'section-card' :layoutType===LAYOUT_TYPE.ONE_COLUMN}">
        <div class="resume-section-container-title"
             [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.primaryColor : resumeTheme.twoColumnHighlightedPrimaryText">
          {{ resumeHasHobbyOutDto?.hobby }}
        </div>
        <div class="resume-section-container-text"
             [ngClass]="resumeHasHobbyOutDto?.hobbyFrequency"
             [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.secondaryColor : resumeTheme.twoColumnHighlightedSecondaryText">
          {{ HOBBY_FREQUENCY_TYPE_DISPLAY[resumeHasHobbyOutDto?.hobbyFrequency] }}
        </div>
      </div>
    </div>
  }
</div>
