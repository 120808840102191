import {ResumeThemeUtil} from "./theme/resume-theme.util";
import {ResumeThemeModel} from "./theme/resume-theme.model";
import {LAYOUT_TYPE} from "./layout-selection.model";

export class SelectThemeUtil {
  static setResumeTheme(isClassicType: boolean, layoutType: LAYOUT_TYPE, theme: string): ResumeThemeModel {
    if (isClassicType) {
      return ResumeThemeUtil.getThemeForClassicLayout();
    } else {
      return ResumeThemeUtil.getResumeTheme(layoutType, theme);
    }
  }
}
