import 'firebase/database';
import {Injectable} from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class EdgeFirebaseApiService {
    private apiUrl = '/api_url';
    private shortLogoUrl = '/short_logo_url';
    private longLogoUrl = '/long_logo_url';

    constructor(private firebaseDatabase: AngularFireDatabase) {
    }

    getApiUrl(subdomain: string) {
        const edgeDatabase = this.firebaseDatabase.database.app.database(environment.edgeFirebase.databaseURL)
            .ref(`${subdomain}${this.apiUrl}`);
        return this.firebaseDatabase.object(edgeDatabase).valueChanges();
    }

    getShortLogoUrl(subdomain: string) {
        const edgeDatabase = this.firebaseDatabase.database.app.database(environment.edgeFirebase.databaseURL)
            .ref(`${subdomain}${this.shortLogoUrl}`);
        return this.firebaseDatabase.object(edgeDatabase).valueChanges();
    }

    getLongLogoUrl(subdomain: string) {
        const edgeDatabase = this.firebaseDatabase.database.app.database(environment.edgeFirebase.databaseURL)
            .ref(`${subdomain}${this.longLogoUrl}`);
        return this.firebaseDatabase.object(edgeDatabase).valueChanges();
    }
}
