<div [style.background-color]="isClassicType ? classicLayoutTheme?.backgroundColor : resumeTheme?.pageBackground"
     class="box-shadow min-h-screen max-width-screen sm:w-full rounded-xl relative resume-container m-auto flex">
    <div class="w-full p-2 sm:p-4 fade-in left-side flex flex-col border-r border-solid"
         [style.background-color]="isClassicType ? classicLayoutTheme?.headerColor : resumeTheme?.highlightedBackground"
         [style.border-radius]="isClassicType ? '0px' : '12px 12px 12px 12px'"
         [style.border-right]="isClassicType ? 'classicLayoutTheme?.borderColor' : 'none'">
        @if (profileImage && !isClassicType) {
            <img [src]="profileImage" alt="profileImage" class="w-full fade-in rounded-3xl mb-3"/>
        }
        @if (resumeHasSkillOutDtos?.length > 0) {
            <app-r-skill [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.SKILL_GROUP)"
                         [resumeTheme]="resumeTheme"
                         [resumeHasSkillOutDtos]="resumeHasSkillOutDtos"
                         [layoutType]="resumeOutDto?.layoutType"
                         [isClassicType]="isClassicType"
                         [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.SKILL_GROUP)">
            </app-r-skill>
        }
        @if (resumeHasEducationOutDtos?.length > 0) {
            <app-r-education [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.EDUCATION)"
                             [resumeTheme]="resumeTheme"
                             [isClassicType]="isClassicType"
                             [resumeHasEducationOutDtos]="resumeHasEducationOutDtos"
                             [layoutType]="resumeOutDto?.layoutType"
                             [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.EDUCATION)">
            </app-r-education>
        }
        @if (resumeHasLanguageOutDtos?.length > 0) {
            <app-r-language [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.LANGUAGES)"
                            [resumeTheme]="resumeTheme"
                            [resumeHasLanguageOutDtos]="resumeHasLanguageOutDtos"
                            [layoutType]="resumeOutDto?.layoutType"
                            [isClassicType]="isClassicType"
                            [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.LANGUAGES)">
            </app-r-language>
        }
        @if (resumeHasHobbyOutDtos?.length > 0) {
            <app-r-hobby [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.HOBBIES)"
                         [resumeTheme]="resumeTheme"
                         [resumeHasHobbyOutDtos]="resumeHasHobbyOutDtos"
                         [layoutType]="resumeOutDto?.layoutType"
                         [isClassicType]="isClassicType"
                         [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.HOBBIES)">
            </app-r-hobby>
        }
        @if (resumeHasCertificateOutDtos?.length > 0) {
            <app-r-certificate [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.CERTIFICATES)"
                               [resumeTheme]="resumeTheme"
                               [certificates]="resumeHasCertificateOutDtos"
                               [layoutType]="resumeOutDto?.layoutType"
                               [isClassicType]="isClassicType"
                               [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.CERTIFICATES)">
            </app-r-certificate>
        }
    </div>
    <div class="relative w-full pb-3">
        <div class="p-2 sm:p-4 fade-in flex flex-col right-section"
             [style.background-color]="isClassicType ? classicLayoutTheme?.backgroundColor : resumeTheme?.pageBackground"
             [style.border-radius]="isClassicType ? '0px' : '0 12px 12px 0'">
            <app-r-personal-information [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.PERSONAL_INFORMATION)"
                                        [resumeTheme]="resumeTheme"
                                        [resumeHasPersonalInformationOutDto]="resumeHasPersonalInformationOutDto"
                                        [socialLinks]="resumeHasSocialLinkOutDtos"
                                        [isClassicType]="isClassicType"
                                        [layoutType]="resumeOutDto?.layoutType">
            </app-r-personal-information>
            @if (resumeHasSummaryOutDto?.summary?.length > 0) {
                <app-r-summary [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.SUMMARY)"
                               [resumeTheme]="resumeTheme"
                               [isClassicType]="isClassicType"
                               [resumeHasSummaryOutDto]="resumeHasSummaryOutDto">
                </app-r-summary>
            }
            @if (resumeHasSocialLinkOutDtos?.length > 0 && isClassicType) {
                <app-r-social-link [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.SOCIAL_LINKS)"
                                   [resumeTheme]="resumeTheme"
                                   [socialLinks]="resumeHasSocialLinkOutDtos"
                                   [isClassicType]="isClassicType"></app-r-social-link>
            }
            @if (resumeHasWorkExperienceOutDtos?.length > 0) {
                <app-r-work-experience [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.WORK_EXPERIENCE)"
                                       [resumeTheme]="resumeTheme"
                                       [isClassicType]="isClassicType"
                                       [resumeHasWorkExperienceOutDtos]="resumeHasWorkExperienceOutDtos"
                                       [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.WORK_EXPERIENCE)">
                </app-r-work-experience>
            }
            @if (resumeHasProjectOutDtos?.length > 0) {
                <app-r-project [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.PROJECTS)"
                               [resumeTheme]="resumeTheme"
                               [resumeHasProjectOutDtos]="resumeHasProjectOutDtos"
                               [isClassicType]="isClassicType"
                               [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.PROJECTS)">
                </app-r-project>
            }
            @if (resumeHasAchievementOutDtos?.length > 0) {
                <app-r-achievement [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.ACHIEVEMENTS)"
                                   [resumeTheme]="resumeTheme"
                                   [resumeHasAchievementOutDtos]="resumeHasAchievementOutDtos"
                                   [isClassicType]="isClassicType"
                                   [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.ACHIEVEMENTS)">
                </app-r-achievement>
            }
            @if (resumeHasReferenceOutDtos?.length > 0) {
                <app-r-reference [style.order]="getIndexOfSection(RESUME_SECTION_TYPE.REFERENCE)"
                                 [resumeTheme]="resumeTheme"
                                 [resumeHasReferenceOutDtos]="resumeHasReferenceOutDtos"
                                 [isClassicType]="isClassicType"
                                 [sectionTitle]="extractTitle(RESUME_SECTION_TYPE.REFERENCE)">
                </app-r-reference>
            }
        </div>
        <div class="w-full max-h-fit pr-2">
            @if (!resumeOutDto?.removeBranding) {
                <app-r-resume-branding [resumeTheme]="resumeTheme"
                                       [isClassicType]="isClassicType"
                                       [removeBranding]="resumeOutDto?.removeBranding"
                                       [brandingImageUrl]="resumeOutDto?.brandingImageUrl">
                </app-r-resume-branding>
            }
        </div>
    </div>
</div>
