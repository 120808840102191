<div class="card-container box-shadow w-full p-8 mt-5">
  <div class="heading-container flex justify-between items-center">
    <div class="sub-heading-container">
      <div class="flex gap-2 items-center">
        @if (title) {
          <div class="title text-xl font-semibold leading-9">{{ title }}</div>
        }
        @if (url) {
          <div class="text-3xl ti ti-circle-arrow-up-right cursor-pointer" (click)="openLink()"></div>
        }
      </div>
      @if (subTitle) {
        <div class="sub-title text-lg font-normal leading-9">{{ subTitle }}</div>
      }
    </div>
    <div class="sub-heading-two-container">
      <div class="duration-container flex gap-4 items-center">
        @if (present) {
          <div class="sub-title text-lg font-normal leading-9">
            {{ startDate | date: DateFormatter.MONTH_YEAR }}
            - Present
          </div>
        }
        @if (!present) {
          <div class="sub-title text-lg font-normal leading-9">
            {{ startDate | date: DateFormatter.MONTH_YEAR }}
            @if (endDate) {
              <span> - </span>
            }
            {{ endDate | date: DateFormatter.MONTH_YEAR }}
          </div>
        }
        @if (startDate && endDate) {
          <div
            class="chip-title py-1 px-4 text-base rounded-full font-semibold leading-normal tracking-tighter">
            {{ dateDifference }}
          </div>
        }
      </div>
      @if (location) {
        <div class="university-title sub-title text-lg font-normal leading-9 flex place-content-end">{{ location }}
        </div>
      }
    </div>
  </div>
  @if (description) {
    <div class="description-container mt-2">
      <div class="sub-title text-base font-normal leading-9" [innerHTML]="description">
      </div>
    </div>
  }
</div>
