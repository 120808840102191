import {Component, Input, OnInit} from '@angular/core';
import {PlacementDriveHasJobOutDto, PublicApiUniversityHasDriveOutDto} from "../service/placementDrive.service";
import {DateFormatter, DateUtil} from "../../common/util/date.util";
import {DatePipe} from "@angular/common";
import {LinkUtil} from "../../common/util/link.util";

@Component({
  selector: 'tal-job-card-deprecated',
  standalone: true,
  imports: [
    DatePipe
  ],
  templateUrl: './job-card-deprecated.component.html',
  styleUrl: './job-card-deprecated.component.scss'
})
export class JobCardDeprecatedComponent implements OnInit {

  @Input() placementDriveHasJobOutDto: PlacementDriveHasJobOutDto;
  @Input() publicApiUniversityHasDriveOutDto: PublicApiUniversityHasDriveOutDto;
  @Input() isPlacementDriveJobExpired: boolean;
  @Input() defaultCompanyLogo: string;
  DateFormatter = DateFormatter;
  remainingDays: number;
  currentDate = new Date();

  ngOnInit(): void {
    this.setDate();
  }

  visitWebsite(): void {
      LinkUtil.openLinkInNewTab(this.placementDriveHasJobOutDto?.jobPostOutDto?.companyOutDto?.websiteLink);
  }

  private setDate(): void {
    const currentDate: string = this.currentDate.toISOString();
    this.remainingDays = DateUtil.dateDifference(this.publicApiUniversityHasDriveOutDto.toDate, currentDate);
  }
}
