import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {EdgeFirebaseApiService} from "../../firebase/common/service/edge-firebase-api.service";

@Injectable({
    providedIn: 'root'
})
export class SubDomainStore {

    protected default_short_logo = 'assets/branding/logo/default_short_logo.svg';

    private _subdomain = new BehaviorSubject<string>('');
    public subdomain: Observable<string>;

    private _baseUrl = new BehaviorSubject<string>('');
    public baseUrl: Observable<string>;

    private _shortLogoUrl = new BehaviorSubject<string>('');
    public shortLogoUrl: Observable<string>;

    private _longLogoUrl = new BehaviorSubject<string>('');
    public longLogoUrl: Observable<string>;

    private _apiUrl = new BehaviorSubject<string>('');
    public apiUrl: Observable<string>;

    constructor(private edgeFirebaseApiService: EdgeFirebaseApiService) {
        this.initialize();
        this.subdomain = this._subdomain.asObservable();
        this.baseUrl = this._baseUrl.asObservable();
        this.shortLogoUrl = this._shortLogoUrl.asObservable();
        this.longLogoUrl = this._longLogoUrl.asObservable();
        this.apiUrl = this._apiUrl.asObservable();
    }

    private initialize(): void {
        const subdomain = window.location.hostname.split('.')[0];
        this.publishSubdomain(subdomain);
        this.publishBaseUrl(this.getBaseUrl());
        this.setApiUrl(subdomain);
        this.setShortLogoUrl(subdomain);
        this.setLongLogoUrl(subdomain);
    }

    private getBaseUrl(): string {
        return `${window.location.protocol}//${window.location.host}/`;
    }

    private publishSubdomain(subdomain: string): void {
        this._subdomain.next(subdomain);
    }

    private publishBaseUrl(baseUrl: string): void {
        this._baseUrl.next(baseUrl);
    }

    private publishApiUrl(apiUrl: string): void {
        this._apiUrl.next(apiUrl);
    }

    private publishShortLogoUrl(shortLogoUrl: string): void {
        this._shortLogoUrl.next(shortLogoUrl);
    }

    private publishLongLogoUrl(longLogoUrl: string): void {
        this._longLogoUrl.next(longLogoUrl);
    }

    private setApiUrl(subdomain: string): void {
        this.edgeFirebaseApiService.getApiUrl(subdomain).subscribe(apiUrl => {
            if (apiUrl) {
                this.publishApiUrl(apiUrl as string);
            }
        });
    }

    private setShortLogoUrl(subdomain: string): void {
        this.publishShortLogoUrl(this.default_short_logo);
        this.edgeFirebaseApiService.getShortLogoUrl(subdomain).subscribe(shortLogoUrl => {
            let shortLogoUrlStr = shortLogoUrl as string;
            console.log('setShortLogoUrl shortLogoUrlStr:', shortLogoUrlStr)
            shortLogoUrlStr = shortLogoUrlStr?.length > 0 ? shortLogoUrlStr : this.default_short_logo;
            this.publishShortLogoUrl(shortLogoUrlStr);
        });
    }

    private setLongLogoUrl(subdomain: string): void {
        this.publishLongLogoUrl('assets/branding/logo/default_long_logo.png');
        this.edgeFirebaseApiService.getLongLogoUrl(subdomain).subscribe(longLogoUrl => {
            let longLogoUrlStr = longLogoUrl as string;
            console.log('setLongLogoUrl longLogoUrlStr:', longLogoUrlStr)
            longLogoUrlStr = longLogoUrlStr?.length > 0 ? longLogoUrlStr : 'assets/branding/logo/default_long_logo.png';
            this.publishLongLogoUrl(longLogoUrlStr);
        });
    }
}
