import {Injectable} from '@angular/core';
import {HttpClientWrapperService} from "../../service/http-client-wrapper.service";
import {AttachmentOutDto, JobPostOutDto} from "../../employer/service/company.service";
import {PLACEMENT_DRIVE_TYPE} from "../../employer/enum/company.model";
import {Observable} from "rxjs";
import {PLACEMENT_DRIVE_STATUS} from "./placement-drive.model";

@Injectable({
  providedIn: 'root'
})
export class PlacementDriveService {
  private publicUrl = "/public";
  private universityUrl = "/university";
  private placementDriveUrl = "/placementDrives";

  constructor(private httpClientWrapperService: HttpClientWrapperService) {
  }

  findPublicDriveJobs(universityId: string, driveId: string): Observable<PublicApiUniversityHasDriveOutDto> {
    return this.httpClientWrapperService.get<PublicApiUniversityHasDriveOutDto>(`${this.getUrl(universityId)}/${driveId}`);
  }

  findAll(universityId: string): Observable<PublicApiUniversityHasDriveOutDto[]> {
    return this.httpClientWrapperService.get<PublicApiUniversityHasDriveOutDto[]>(this.getUrl(universityId));
  }

  private getUrl(universityId: string): string {
    return `${this.publicUrl}${this.universityUrl}/${universityId}${this.placementDriveUrl}`;
  }
}

export class PublicApiUniversityHasDriveOutDto {
  id: string;
  name: string;
  fromDate: string;
  toDate: string;
  startTime: string;
  endTime: string;
  address: string;
  openForAll: Boolean;
  placementDriveType: PLACEMENT_DRIVE_TYPE;
  placementDriveStatus: PLACEMENT_DRIVE_STATUS;
  placementDriveHasJobOutDtos: PlacementDriveHasJobOutDto[];
  universityLogoOutDto: AttachmentOutDto;
  universityCoverPictureOutDto: AttachmentOutDto;
}

export class PlacementDriveHasJobOutDto {
  id: string;
  jobPostOutDto: JobPostOutDto;
}
