import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {RequestParam} from "../common/model/request-param";
import {RequestHeader} from "../common/model/request-header";

@Injectable({
  providedIn: 'root'
})
export class HttpClientWrapperService {

  private readonly baseUrl: string;

  private readonly requestOptions = {
    params: new HttpParams(),
    headers: new HttpHeaders()
  };

  constructor(private readonly http: HttpClient) {
    this.baseUrl = environment.baseApiUrl;
  }

  get<T>(url: string, requestParams?: RequestParam[], requestHeaders?: RequestHeader[]): Observable<T> {
    this.appendRequestParams(requestParams);
    this.requestOptions.headers = this.attachPublicApiKeyToHeaders();
    return this.http.get<T>(this.baseUrl + url, this.requestOptions);
  }

  post<T>(url: string, data?: any, requestParams?: RequestParam[], requestHeaders?: RequestHeader[]): Observable<T> {
    this.appendRequestParams(requestParams);
    this.requestOptions.headers = this.attachPublicApiKeyToHeaders();
    return this.http.post<T>(this.baseUrl + url, data, this.requestOptions);
  }

  put<T>(url: string, data?: any, requestParams?: RequestParam[], requestHeaders?: RequestHeader[]): Observable<T> {
    this.appendRequestParams(requestParams);
    this.requestOptions.headers = this.attachPublicApiKeyToHeaders();
    return this.http.put<T>(this.baseUrl + url, data, this.requestOptions);
  }

  patch<T>(url: string, data?: any, requestParams?: RequestParam[], requestHeaders?: RequestHeader[]): Observable<T> {
    this.appendRequestParams(requestParams);
    this.requestOptions.headers = this.attachPublicApiKeyToHeaders();
    return this.http.patch<T>(this.baseUrl + url, data, this.requestOptions);
  }

  delete(url: string, requestParams?: RequestParam[], requestHeaders?: RequestHeader[]) {
    this.appendRequestParams(requestParams);
    this.requestOptions.headers = this.attachPublicApiKeyToHeaders();
    return this.http.delete(this.baseUrl + url, this.requestOptions);
  }

  deleteWithRequestBody(url: string, data?: any, requestParams?: RequestParam[], requestHeaders?: RequestHeader[]) {
    this.appendRequestParams(requestParams);
    this.requestOptions.headers = this.attachPublicApiKeyToHeaders();
    const requestOptions = {
      params: this.requestOptions.params,
      headers: this.requestOptions.headers,
      body: data
    };
    return this.http.delete(this.baseUrl + url, requestOptions);
  }

  download(url: string): Observable<ArrayBuffer> {
    return this.http.get(this.baseUrl + url, {
      responseType: 'arraybuffer',
      headers: new HttpHeaders()
    });
  }

  private appendRequestParams(requestParams?: RequestParam[]): void {
    this.requestOptions.params = new HttpParams();
    if (requestParams !== null && requestParams !== undefined) {
      requestParams.forEach(requestParam =>
        this.requestOptions.params = this.requestOptions.params.append(requestParam.key, requestParam.value));
    }
  }

  attachPublicApiKeyToHeaders(): HttpHeaders {
    return new HttpHeaders().append('api-key', environment.publicApiKey);
  }
}
