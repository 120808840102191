@if (companyOutDto?.careerSiteOutDto?.summary) {
  <div class="abouts-section">
    <div class="about-us-heading job-title text-2xl font-medium leading-6 ml-2">About the company</div>
    <div class="about-us-content text-base leading-8 m-5 font-poppins"
         [innerHTML]="companyOutDto?.careerSiteOutDto?.summary">
    </div>
  </div>
} @else {
  <div class="grid place-content-center text-center p-4">
    <img class="ml-auto mr-auto" src="assets/images/astronaut-logo.png" alt="">
    <h1 class="text-2xl font-medium leading-6 mt-5">No Company Details Available!!!</h1>
  </div>
}
