import {Component, Input} from '@angular/core';
import {CompanyOutDto} from "../../service/company.service";

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss'
})
export class AboutUsComponent {
  @Input() companyOutDto: CompanyOutDto;
  @Input() companyUrl: string;
}
