import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RESUME_THEME_TYPE, ResumeOutDto, ResumeSectionDataOutDto} from "../common/resume.model";
import {ResumeHasPersonalInformationOutDto} from "../common/resume-section-model/personal-information.model";
import {ResumeHasSummaryOutDto} from "../common/resume-section-model/summary.model";
import {ResumeHasAchievementOutDto} from "../common/resume-section-model/achievement.model";
import {ResumeHasCertificateOutDto} from "../common/resume-section-model/certificate.model";
import {ResumeHasEducationOutDto} from "../common/resume-section-model/education.model";
import {ResumeHasHobbyOutDto} from "../common/resume-section-model/hobby.model";
import {ResumeHasLanguageOutDto} from "../common/resume-section-model/languagae.model";
import {ResumeHasSocialLinkOutDto} from "../common/resume-section-model/social-link.model";
import {ResumeHasWorkExperienceOutDto} from "../common/resume-section-model/work-experience.model";
import {ResumeHasProjectOutDto} from "../common/resume-section-model/project.model";
import {ClassicLayoutModel, ResumeThemeModel} from "../common/theme/resume-theme.model";
import {ResumeHasReferenceOutDto} from "../common/resume-section-model/reference.model";
import {ResumeHasSkillOutDto} from "../common/resume-section-model/skill.model";
import {NgStyle} from "@angular/common";
import {RPersonalInformationComponent} from "../component/r-personal-information/r-personal-information.component";
import {RSummaryComponent} from "../component/r-summary/r-summary.component";
import {RWorkExperienceComponent} from "../component/r-work-experience/r-work-experience.component";
import {RProjectComponent} from "../component/r-project/r-project.component";
import {RAchievementComponent} from "../component/r-achievement/r-achievement.component";
import {RReferenceComponent} from "../component/r-reference/r-reference.component";
import {RSkillComponent} from "../component/r-skill/r-skill.component";
import {RLanguageComponent} from "../component/r-language/r-language.component";
import {RHobbyComponent} from "../component/r-hobby/r-hobby.component";
import {RCertificateComponent} from "../component/r-certificate/r-certificate.component";
import {REducationComponent} from "../component/r-education/r-education.component";
import {RESUME_SECTION_TYPE} from "../common/resume-section.model";
import {RResumeBrandingComponent} from "../component/r-resume-branding/r-resume-branding.component";
import {ResumeClassicLayoutUtil} from "../common/theme/resume-classic-layout.util";
import {ATS_LAYOUT_TYPE} from "../common/layout-selection.model";
import {SelectThemeUtil} from "../common/select-theme.util";
import {RSocialLinkComponent} from "../component/r-social-link/r-social-link.component";
import {Subscription} from "rxjs";
import {ResumeStore} from "../../ai-resume/common/store/resume.store";

@Component({
  selector: 'app-two-column-resume',
  standalone: true,
  imports: [
    NgStyle,
    RPersonalInformationComponent,
    RSummaryComponent,
    RWorkExperienceComponent,
    RProjectComponent,
    RAchievementComponent,
    RReferenceComponent,
    RSkillComponent,
    RLanguageComponent,
    RHobbyComponent,
    RCertificateComponent,
    REducationComponent,
    RResumeBrandingComponent,
    RSocialLinkComponent
  ],
  templateUrl: './two-column-resume.component.html',
  styleUrl: './two-column-resume.component.scss'
})
export class TwoColumnResumeComponent implements OnInit, OnDestroy {
  @Input() resumeOutDto: ResumeOutDto;
  @Input() resumeSectionDataOutDtos: ResumeSectionDataOutDto[];
  resumeHasPersonalInformationOutDto: ResumeHasPersonalInformationOutDto;
  profileImage: string;
  resumeHasSummaryOutDto: ResumeHasSummaryOutDto;
  resumeHasAchievementOutDtos: ResumeHasAchievementOutDto[];
  resumeHasCertificateOutDtos: ResumeHasCertificateOutDto[];
  resumeHasEducationOutDtos: ResumeHasEducationOutDto[];
  resumeHasHobbyOutDtos: ResumeHasHobbyOutDto[];
  resumeHasLanguageOutDtos: ResumeHasLanguageOutDto[];
  resumeHasSocialLinkOutDtos: ResumeHasSocialLinkOutDto[];
  resumeHasWorkExperienceOutDtos: ResumeHasWorkExperienceOutDto[];
  resumeHasProjectOutDtos: ResumeHasProjectOutDto[];
  resumeHasReferenceOutDtos: ResumeHasReferenceOutDto[];
  resumeHasSkillOutDtos: ResumeHasSkillOutDto[];
  ATS_LAYOUT_TYPE: ATS_LAYOUT_TYPE;
  themeType: RESUME_THEME_TYPE;
  resumeTheme: ResumeThemeModel;
  classicLayoutTheme: ClassicLayoutModel = ResumeClassicLayoutUtil.getClassicLayoutTheme();
  protected readonly RESUME_SECTION_TYPE = RESUME_SECTION_TYPE;
  private subscription = new Subscription();

  constructor(private resumeStore: ResumeStore) {
  }

  ngOnInit(): void {
    this.subscribeToResumeStore();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get isClassicType(): boolean {
    return this.ATS_LAYOUT_TYPE === ATS_LAYOUT_TYPE.CLASSIC;
  }

  setResumeOutDto(resumeOutDto: ResumeOutDto): void {
    this.ATS_LAYOUT_TYPE = resumeOutDto?.atsLayout;
    this.resumeHasPersonalInformationOutDto = resumeOutDto?.personalInformation;
    this.profileImage = resumeOutDto?.personalInformation?.imageUrl;
    this.resumeHasSummaryOutDto = resumeOutDto?.summary;
    this.resumeHasAchievementOutDtos = resumeOutDto?.achievements;
    this.resumeHasCertificateOutDtos = resumeOutDto?.certificates;
    this.resumeHasEducationOutDtos = resumeOutDto?.educations;
    this.resumeHasHobbyOutDtos = resumeOutDto?.hobbies;
    this.resumeHasLanguageOutDtos = resumeOutDto?.languages;
    this.resumeHasSocialLinkOutDtos = resumeOutDto?.socialLinks;
    this.resumeHasWorkExperienceOutDtos = resumeOutDto?.workExperiences;
    this.resumeHasReferenceOutDtos = resumeOutDto?.references;
    this.resumeHasProjectOutDtos = resumeOutDto?.projects;
    this.resumeHasSkillOutDtos = resumeOutDto?.skills;
    this.themeType = resumeOutDto?.theme;
  }

  extractTitle(resumeSectionType: string): string {
    const result = this.resumeSectionDataOutDtos?.find(section => section.resumeSectionType === resumeSectionType);
    if (result) {
      return result.title;
    } else {
      return null;
    }
  }

  getIndexOfSection(resumeSectionType: string): number {
    const result = this.resumeSectionDataOutDtos?.find(section => section.resumeSectionType === resumeSectionType);
    if (result) {
      return result.rowIndex;
    } else {
      return null;
    }
  }

  private subscribeToResumeStore(): void {
    this.subscription.add(this.resumeStore.updatedResumeRequired
      .subscribe(resumeOutDto => {
        if (resumeOutDto) {
          this.resumeOutDto = resumeOutDto;
          this.setResumeOutDto(this.resumeOutDto);
          this.setResumeTheme(this.isClassicType);
        }
      }));
  }

  private setResumeTheme(isClassicType: boolean): void {
    this.resumeTheme = SelectThemeUtil.setResumeTheme(isClassicType, this.resumeOutDto?.layoutType, this.themeType);
  }
}
