import {Component} from '@angular/core';
import {talenlioWebsite} from "../../common/website-constant/talenlio-website";
import {LinkUtil} from "../../../../common/util/link.util";

@Component({
  selector: 'app-w-footer',
  standalone: true,
  imports: [],
  templateUrl: './w-footer.component.html',
  styleUrl: './w-footer.component.scss'
})
export class WFooterComponent {
  constructor() {
  }

  navigateToTalenlioWebsite(): void {
      LinkUtil.openLinkInNewTab(talenlioWebsite.url);
  }
}
