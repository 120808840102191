<div class="card-container">
  <div class="heading-container flex gap-4 items-center">
    @if (logo) {
      <div class="logo">
        <img class="w-full" style="width: 50px"
             [src]="logo" alt="">
      </div>
    }
    <div class="sub-title-container">
      @if (title) {
        <div class="title text-xl font-semibold leading-9">{{ title }}</div>
      }
      <div class="flex items-center gap-4 place-content-center">
        @if (startDate) {
          <div
            class="title text-xl font-normal leading-9"> {{ startDate | date: DateFormatter.MONTH_YEAR }}
            To Now
          </div>
        }
        @if (duration) {
          <div
            class="chip-title py-1 px-4 text-base rounded-full font-semibold leading-normal tracking-tighter">{{ duration }}
          </div>
        }
      </div>
    </div>
  </div>
  <div class="description-container mt-3 sub-title text-base font-normal leading-9" [innerHTML]="description"></div>
</div>
