import {Injectable} from '@angular/core';
import {HttpClientWrapperService} from "../../../service/http-client-wrapper.service";
import {Observable} from "rxjs";
import {RequestParam} from "../../../common/model/request-param";
import {WebsiteOutDto} from "../common/website-model/website.model";

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {
  private publicApiUrl = '/public';
  private searchUrl = '/search';
  private websiteUrl = '/websites';
  private urlHandle = 'urlHandle';

  constructor(private httpClientWrapperService: HttpClientWrapperService) {
  }

  findPublicWebsiteByUrlHandle(urlHandle: string): Observable<WebsiteOutDto> {
    return this.httpClientWrapperService.get<any>(`${this.publicApiUrl}${this.websiteUrl}${this.searchUrl}`,
      this.getRequestParams(urlHandle));
  }

  private getRequestParams(urlHandle: string): RequestParam[] {
    return [{key: this.urlHandle, value: urlHandle}];
  }
}
