import {PersonalInformationAttributes} from "./personal-information.model";
import {RecipientAttributes} from "./recipient.model";
import {GreetingParagraphAttributes} from "./greeting-paragraph.model";
import {BodyAttributes} from "./body.model";
import {ClosingMessageAttributes} from "./closing-message.model";
import {SubjectAttributes} from "./subject.model";
import {FormalSalutationAttributes} from "./formal-salutation.model";
import {SocialLinkAttributes} from "./social-link.model";

export class CoverLetterCommonDto {
  title: string;
  recipientAttributes?: RecipientAttributes;
  coverLetterThemeType?: COVER_LETTER_THEME_TYPE;
}

export class CoverLetterOutDto extends CoverLetterCommonDto {
  id: string;
  createdAt: Date;
  lastModifiedAt: Date;
  urlHandle: string;
  personalInformationAttributes: PersonalInformationAttributes;
  greetingParagraphAttributes: GreetingParagraphAttributes;
  bodyAttributes: BodyAttributes;
  closingMessageAttributes: ClosingMessageAttributes;
  subjectAttributes: SubjectAttributes;
  formalSalutationAttributes: FormalSalutationAttributes;
  socialLinkAttributes: SocialLinkAttributes;
}

export enum COVER_LETTER_SECTION_TYPE {
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  SOCIAL_LINK = 'SOCIAL_LINK',
  RECIPIENT = 'RECIPIENT',
  BODY = 'BODY',
  GREETING_PARAGRAPH = 'GREETING_PARAGRAPH',
  FORMAL_SALUTATION = 'FORMAL_SALUTATION',
  SUBJECT = 'SUBJECT',
  CLOSING_MESSAGE = 'CLOSING_MESSAGE'
}

export enum COVER_LETTER_SECTION_TYPE_DISPLAY {
  PERSONAL_INFORMATION = 'Personal Information',
  SOCIAL_LINK = 'Social Link',
  RECIPIENT = 'Recipient',
  BODY = 'Body',
  GREETING_PARAGRAPH = 'Greeting Paragraph',
  FORMAL_SALUTATION = 'Formal Salutation',
  SUBJECT = 'Subject',
  CLOSING_MESSAGE = 'Closing Message'
}

export enum COVER_LETTER_THEME_TYPE {
  AMSTERDAM = 'AMSTERDAM',
  SINGAPORE = 'SINGAPORE'
}
