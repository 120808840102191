import {Component, OnInit} from '@angular/core';
import {WebsiteService} from "./service/website.service";
import {WSidebarComponent} from "./components/w-sidebar/w-sidebar.component";
import {NgClass, NgStyle} from "@angular/common";
import {WHeaderComponent} from "./components/w-header/w-header.component";
import {WIntroComponent} from "./components/w-intro/w-intro.component";
import {WAboutComponent} from "./components/w-about/w-about.component";
import {WWorkComponent} from "./components/w-work/w-work.component";
import {WProjectComponent} from "./components/w-project/w-project.component";
import {WEducationComponent} from "./components/w-education/w-education.component";
import {WCertificateComponent} from "./components/w-certificate/w-certificate.component";
import {WSkillComponent} from "./components/w-skill/w-skill.component";
import {WAchievementComponent} from "./components/w-achievement/w-achievement.component";
import {WLanguageComponent} from "./components/w-language/w-language.component";
import {WHobbiesComponent} from "./components/w-hobbies/w-hobbies.component";
import {WFooterComponent} from "./components/w-footer/w-footer.component";
import {WEBSITE_SECTION_TYPE} from "./common/website-section/website-section.model";
import {WebsiteOutDto} from "./common/website-model/website.model";
import {SummaryAttributes} from "./common/website-model/summary.model";
import {PersonalInformationAttributes} from "./common/website-model/personal-information.model";
import {HeaderSectionAttribute} from "./common/website-model/header.model";
import {WorkExperienceAttributes} from "./common/website-model/work-experience.model";
import {ProjectAttributes} from "./common/website-model/project-out-dto.model";
import {EducationAttributes} from "./common/website-model/education.model";
import {SocialLinkAttributes} from "./common/website-model/social-link-out.dto";
import {CertificateAttributes} from "./common/website-model/certificate.model";
import {AchievementAttributes} from "./common/website-model/achievement-out-dto.model";
import {LanguageAttributes} from "./common/website-model/language.model";
import {SkillAttributes} from "./common/website-model/skill-out-dto.model";
import {WebsiteSectionOutDto} from "./common/website-model/website-section.model";
import {HobbyAttributes} from "./common/website-model/hobby-out-dto.model";
import {ActivatedRoute, Router} from "@angular/router";
import {ROUTE} from "../../common/route-constants/route";

@Component({
  selector: 'app-website',
  standalone: true,
  imports: [
    WSidebarComponent,
    NgStyle,
    NgClass,
    WHeaderComponent,
    WIntroComponent,
    WAboutComponent,
    WWorkComponent,
    WProjectComponent,
    WEducationComponent,
    WCertificateComponent,
    WSkillComponent,
    WAchievementComponent,
    WLanguageComponent,
    WHobbiesComponent,
    WFooterComponent
  ],
  templateUrl: './website.component.html',
  styleUrl: './website.component.scss'
})
export class WebsiteComponent implements OnInit {
  websiteOutDto: WebsiteOutDto;
  summaryAttributes: SummaryAttributes;
  personalInformationAttributes: PersonalInformationAttributes;
  headerSectionAttribute: HeaderSectionAttribute;
  workExperienceAttributes: WorkExperienceAttributes;
  projectAttributes: ProjectAttributes;
  educationAttributes: EducationAttributes;
  socialLinkAttributes: SocialLinkAttributes;
  certificateAttributes: CertificateAttributes;
  achievementAttributes: AchievementAttributes;
  languageAttributes: LanguageAttributes;
  hobbyAttributes: HobbyAttributes;
  skillAttributes: SkillAttributes;
  isExpanded = false;
  isScrolled = false;
  urlHandle: string;

  constructor(private websiteService: WebsiteService,
              private router: Router,
              private route: ActivatedRoute,) {
  }

  ngOnInit() {
    this.getShowCaseUrlHandle();
    this.setWebsiteOutDto();
  }

  onScroll(event: any): void {
    this.isScrolled = false;
    if (!this.isScrolled) {
      setTimeout(() => {
        this.isScrolled = true;
        this.isExpanded = true;
      }, 500);
    }
  }

  toggleWidth(): void {
    this.isExpanded = !this.isExpanded;
  }

  goToSection(sectionId: string): void {
    document.getElementById(sectionId) ? document.getElementById(sectionId).scrollIntoView({behavior: 'smooth'}) : null;
  }

  getShowCaseUrlHandle(): void {
    this.route.params.subscribe(params => {
      this.urlHandle = params[ROUTE.URL_HANDLE];
    });
  }

  setWebsiteOutDto(): void {
    this.websiteService.findPublicWebsiteByUrlHandle(this.urlHandle).subscribe(websiteOutDto => {
        this.websiteOutDto = websiteOutDto;
        this.setWebsiteSectionData(this.websiteOutDto);
      },
      (error) => {
        this.router.navigate([ROUTE.PAGE_NOT_FOUND]);
      });
  }

  private setWebsiteSectionData(websiteOutDto: WebsiteOutDto): void {
    websiteOutDto.websiteSectionOutDtos.forEach((websiteSectionOutDto: WebsiteSectionOutDto) => {
      switch (websiteSectionOutDto.websiteSectionType) {
        case WEBSITE_SECTION_TYPE.SUMMARY.valueOf():
          this.summaryAttributes = websiteSectionOutDto.websiteSectionAttributes as SummaryAttributes;
          break;
        case WEBSITE_SECTION_TYPE.PERSONAL_INFORMATION.valueOf():
          this.personalInformationAttributes = websiteSectionOutDto.websiteSectionAttributes as PersonalInformationAttributes;
          break;
        case WEBSITE_SECTION_TYPE.SOCIAL_LINK.valueOf():
          this.socialLinkAttributes = websiteSectionOutDto.websiteSectionAttributes as SocialLinkAttributes;
          break;
        case WEBSITE_SECTION_TYPE.HEADER.valueOf():
          this.headerSectionAttribute = websiteSectionOutDto.websiteSectionAttributes as HeaderSectionAttribute;
          break;
        case WEBSITE_SECTION_TYPE.WORK_EXPERIENCE.valueOf():
          this.workExperienceAttributes = websiteSectionOutDto.websiteSectionAttributes as WorkExperienceAttributes;
          break;
        case WEBSITE_SECTION_TYPE.PROJECT.valueOf():
          this.projectAttributes = websiteSectionOutDto.websiteSectionAttributes as ProjectAttributes;
          break;
        case WEBSITE_SECTION_TYPE.EDUCATION.valueOf():
          this.educationAttributes = websiteSectionOutDto.websiteSectionAttributes as EducationAttributes;
          break;
        case WEBSITE_SECTION_TYPE.CERTIFICATE.valueOf():
          this.certificateAttributes = websiteSectionOutDto.websiteSectionAttributes as CertificateAttributes;
          break;
        case WEBSITE_SECTION_TYPE.ACHIEVEMENT.valueOf():
          this.achievementAttributes = websiteSectionOutDto.websiteSectionAttributes as AchievementAttributes;
          break;
        case WEBSITE_SECTION_TYPE.SKILL.valueOf():
          this.skillAttributes = websiteSectionOutDto.websiteSectionAttributes as SkillAttributes;
          break;
        case WEBSITE_SECTION_TYPE.LANGUAGE.valueOf():
          this.languageAttributes = websiteSectionOutDto.websiteSectionAttributes as LanguageAttributes;
          break;
        case WEBSITE_SECTION_TYPE.HOBBY.valueOf():
          this.hobbyAttributes = websiteSectionOutDto.websiteSectionAttributes as HobbyAttributes;
          break;
      }
    });
  }
}
