import {Component, Input} from '@angular/core';
import {NgClass, NgStyle, NgTemplateOutlet, TitleCasePipe} from "@angular/common";
import {ClassicLayoutModel, ResumeThemeModel} from "../../common/theme/resume-theme.model";
import {ResumeHasReferenceOutDto} from "../../common/resume-section-model/reference.model";
import {LAYOUT_TYPE} from "../../common/layout-selection.model";
import {RESUME_SECTION_TYPE_DISPLAY} from "../../common/resume-section.model";
import {ResumeClassicLayoutUtil} from "../../common/theme/resume-classic-layout.util";
import {LinkUtil} from "../../../../common/util/link.util";

@Component({
  selector: 'app-r-reference',
  standalone: true,
  imports: [
    NgStyle,
    NgTemplateOutlet,
    NgClass,
    TitleCasePipe
  ],
  templateUrl: './r-reference.component.html',
  styleUrl: './r-reference.component.scss'
})
export class RReferenceComponent {

  @Input()
  resumeTheme?: ResumeThemeModel;
  @Input()
  layoutType: LAYOUT_TYPE;
  @Input()
  resumeHasReferenceOutDtos: ResumeHasReferenceOutDto[];
  @Input()
  sectionTitle: string;
  @Input()
  isClassicType: boolean = false;
  classicLayoutTheme: ClassicLayoutModel = ResumeClassicLayoutUtil.getClassicLayoutTheme();
  protected readonly LAYOUT_TYPE = LAYOUT_TYPE;
  protected readonly RESUME_SECTION_TYPE_DISPLAY = RESUME_SECTION_TYPE_DISPLAY;

  openUrlInNewTab(linkedInUrl: any): void {
      LinkUtil.openLinkInNewTab(linkedInUrl);
  }
}
