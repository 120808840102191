export class ResumeIconModel {
  public static getIconForResume(): ResumeIconUtil {
    return {
      achievement: "ti ti-gift",
      certificate: "ti ti-book",
      education: "ti ti-school",
      email: "ti ti-mail",
      externalLink: "ti ti-external-link",
      hobbies: "ti ti-headphones",
      language: "ti ti-language",
      link: "ti ti-link",
      location: "ti ti-map-pin",
      phone: "ti ti-phone",
      project: "ti ti-clipboard",
      references: "ti ti-users",
      skill: "ti ti-award",
      socialLinks: this.socialLinkIcons(),
      summary: "ti ti-notes",
      workExperience: "ti ti-briefcase"
    } as ResumeIconUtil;
  }

  private static socialLinkIcons(): SocialLinkIconUtil {
    return {
      linkedin: "ti-brand-linkedin",
      website: "ti ti-world",
      behance: "ti ti-brand-behance",
      dribbble: "ti ti-brand-dribbble",
      github: "ti ti-brand-github"
    } as SocialLinkIconUtil;
  }
}

export interface ResumeIconUtil {
  summary: string;
  education: string;
  workExperience: string;
  certificate: string;
  project: string;
  language: string;
  skill: string;
  hobbies: string;
  achievement: string;
  references: string;
  email: string;
  phone: string;
  location: string;
  link: string;
  externalLink: string;
  socialLinks: SocialLinkIconUtil;
}

export interface SocialLinkIconUtil {
  linkedin: string;
  website: string;
  behance: string;
  dribbble: string;
  github: string;
}

export enum CONTACT_NUMBER_TYPE_ICON {
  MOBILE = 'ti ti-phone',
  LANDLINE = 'ti ti-device-landline-phone',
  WHATSAPP = 'ti ti-brand-whatsapp'
}
