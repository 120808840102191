import {ProjectOutDtoModel} from "./project-out-dto.model";
import {ExperienceOutDto} from "./work-experience.model";
import {QualificationOutDto} from "./education.model";
import {SocialLinkOutDto} from "./social-link-out.dto";
import {SkillOutDtoModel} from "./skill-out-dto.model";

import {LanguageModel} from "./language.model";
import {HobbyOutDtoModel} from "./hobby-out-dto.model";
import {AchievementOutDtoModel} from "./achievement-out-dto.model";
import {CertificationOutDto} from "./certificate.model";
import {HeaderSectionAttribute} from "./header.model";

export enum WEBSITE_SECTION_TYPE_ID {
  PERSONAL_INFORMATION = 'personal-information',
  HEADER_SECTION = 'header-section',
  SUMMARY = 'summary',
  SKILL = 'skill',
  WORK_EXPERIENCE = 'work-experience',
  PROJECT = 'project',
  EDUCATION = 'education',
  CERTIFICATE = 'certificate',
  ACHIEVEMENT = 'achievement',
  HOBBY = 'hobby',
  SOCIAL_LINK = 'social-link',
  LANGUAGE = 'language'
}

export enum WEBSITE_SECTION_TYPE {
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  HEADER = 'HEADER',
  ABOUT_ME = 'ABOUT_ME',
  SKILL = 'SKILL',
  WORK_EXPERIENCE = 'WORK_EXPERIENCE',
  PROJECT = 'PROJECT',
  EDUCATION = 'EDUCATION',
  CERTIFICATE = 'CERTIFICATE',
  ACHIEVEMENT = 'ACHIEVEMENT',
  HOBBY = 'HOBBY',
  SOCIAL_LINK = 'SOCIAL_LINK',
  LANGUAGE = 'LANGUAGE'
}

export enum WEBSITE_SECTION_TYPE_DISPLAY {
  PERSONAL_INFORMATION = 'Personal Information',
  HEADER_SECTION = 'Header',
  SUMMARY = 'Summary',
  SKILL = 'Skills',
  WORK_EXPERIENCE = 'Work Experience',
  PROJECTS = 'Projects',
  EDUCATION = 'Education',
  CERTIFICATE = 'Certificates',
  ACHIEVEMENT = 'Achievements',
  HOBBY = 'Hobbies',
  SOCIAL_LINK = 'Social links',
  LANGUAGE = 'Languages'
}

export class WebsiteSectionAttributes {
  public websiteSectionType: WEBSITE_SECTION_TYPE;
  public languages?: LanguageModel[];
  public hobbies?: HobbyOutDtoModel[];
  public projects?: ProjectOutDtoModel[];
  public experiences?: ExperienceOutDto[];
  public qualifications?: QualificationOutDto[];
  public socialLinks?: SocialLinkOutDto[];
  public skills?: SkillOutDtoModel[];
  public certificates?: CertificationOutDto[];
  public achievements?: AchievementOutDtoModel[];
  public headerSection?: HeaderSectionAttribute;
}

export class WebsiteSectionCommonDto {
  public title: string;
  public isActive: boolean;
  public websiteSectionType: WEBSITE_SECTION_TYPE;
  public websiteSectionAttributes: WebsiteSectionAttributes;
}

export class WebsiteSectionInDto extends WebsiteSectionCommonDto {
}

export class WebsiteSectionOutDto extends WebsiteSectionCommonDto {
  public id: string;
}
