import {Component, Input} from '@angular/core';
import {ResumeThemeUtil} from "../../common/theme/resume-theme.util";
import {NgStyle, NgTemplateOutlet} from "@angular/common";

@Component({
  selector: 'app-r-resume-branding',
  standalone: true,
  imports: [
    NgStyle,
    NgTemplateOutlet
  ],
  templateUrl: './r-resume-branding.component.html',
  styleUrl: './r-resume-branding.component.scss'
})
export class RResumeBrandingComponent {
  @Input()
  resumeTheme?: ResumeThemeUtil;
  @Input()
  removeBranding: boolean;
  @Input()
  isClassicType: boolean = false;
  @Input()
  brandingImageUrl: string;
  defaultBrandingImageUrl = 'assets/resume/made-with-love-branding.png';
}
