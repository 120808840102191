import {Component, Input} from '@angular/core';
import {SECTION_LIST_DISPLAY} from "../../common/nav-menu-list";
import {SummaryAttributes} from "../../common/website-model/summary.model";
import {WEBSITE_SECTION_TYPE} from "../../common/website-model/website-section.model";
import {DateFormatter, DateUtil} from "../../../../common/util/date.util";
import {DatePipe} from "@angular/common";
import {WebsiteSectionCardComponent} from "../../website-section-card/website-section-card.component";

@Component({
  selector: 'app-w-about',
  standalone: true,
  imports: [
    DatePipe,
    WebsiteSectionCardComponent
  ],
  templateUrl: './w-about.component.html',
  styleUrl: './w-about.component.scss'
})
export class WAboutComponent {
  @Input() summaryAttributes: SummaryAttributes;
  SECTION_LIST_DISPLAY = SECTION_LIST_DISPLAY;
  WEBSITE_SECTION_TYPE = WEBSITE_SECTION_TYPE;
  protected readonly DateUtil = DateUtil;
  protected readonly DateFormatter = DateFormatter;
}
