<div id="{{WEBSITE_SECTION_TYPE.SKILL}}" class="my-skill-section mt-20">
  <tal-website-section-card logo="assets/website/project-icon.png"
                            [title]="SECTION_LIST_DISPLAY.SKILL">
  </tal-website-section-card>
  <div class="flex flex-wrap p-6 gap-2 my-skill-card-section backdrop-blur-6.5 website-box-shadow border-website rounded-3xl mt-5">
    @for (skill of skillAttributes?.skills; track skill?.id) {
      <div class="my-skill-card-list">
            {{ skill?.name }}
      </div>
    }
  </div>
</div>
