export enum WEBSITE_SECTION_TYPE_ID {
  PERSONAL_INFORMATION = 'personal-information',
  HEADER_SECTION = 'header-section',
  SUMMARY = 'summary',
  SKILL = 'skill',
  WORK_EXPERIENCE = 'work-experience',
  PROJECT = 'project',
  EDUCATION = 'education',
  CERTIFICATE = 'certificate',
  ACHIEVEMENT = 'achievement',
  HOBBY = 'hobby',
  SOCIAL_LINK = 'social-link',
  LANGUAGE = 'language'
}

export enum WEBSITE_SECTION_TYPE {
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  HEADER = 'HEADER',
  SUMMARY = 'SUMMARY',
  SKILL = 'SKILL',
  WORK_EXPERIENCE = 'WORK_EXPERIENCE',
  PROJECT = 'PROJECT',
  EDUCATION = 'EDUCATION',
  CERTIFICATE = 'CERTIFICATE',
  ACHIEVEMENT = 'ACHIEVEMENT',
  HOBBY = 'HOBBY',
  SOCIAL_LINK = 'SOCIAL_LINK',
  LANGUAGE = 'LANGUAGE'
}

export enum WEBSITE_SECTION_TYPE_DISPLAY {
  PERSONAL_INFORMATION = 'Personal Information',
  HEADER_SECTION = 'Header',
  SUMMARY = 'Summary',
  SKILL = 'Skills',
  WORK_EXPERIENCE = 'Work Experience',
  PROJECTS = 'Projects',
  EDUCATION = 'Education',
  CERTIFICATE = 'Certificates',
  ACHIEVEMENT = 'Achievements',
  HOBBY = 'Hobbies',
  SOCIAL_LINK = 'Social links',
  LANGUAGE = 'Languages'
}
